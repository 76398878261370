#uploader-app {

  .file-dropzone {
    border: 2px dashed #ccc;
    padding: 8px;
  }

  .file-list{
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .align-right {
    text-align: right !important;
  }

  .button-row {
    margin-bottom: 16px;
  }

}
