@media print {
  html, body, .md-dialog-container {
    height: auto !important;
    width: auto !important;
    min-height: 0 !important;
    min-width: 0 !important;
    overflow: visible !important;
    float: none !important;
    position: relative !important;
  }

  .md-dialog-container {
    width: 100% !important;
  }

  .md-scroll-mask {
    display: none !important;
  }

  body {
    background-color: transparent !important;
    margin: 24px;
  }

  * {
    text-shadow: none !important;
    color: #000 !important; // Black prints faster: h5bp.com/s
    background: transparent !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  // Don't show links for images, or javascript/internal links
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-before: always;
    page-break-after: avoid;
  }

  tr,
  th,
  td{
    min-width: 375px;
  }
  .table {
    td,
    th {
      background-color: #fff !important;
    }
  }
  .btn,
  .dropup > .btn {
    > .caret {
      border-top-color: #000 !important;
    }
  }
  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table-bordered {
    th,
    td {
      border: 1px solid #ddd !important;
    }
  }

  /* VHR specific */

  &[style] {
    position: relative !important;
  }

  & * {
    font-size: 10pt;
    /*font-family: Georgia, 'Times New Roman', serif;*/
    font-family: monospace;
    line-height: 1.2;
    float: none !important;
    height: auto !important;
    overflow: visible !important;
    position: relative !important;
    transition:none!important;
    width: auto !important;
  }

  style {
    display: none !important;
  }

  .div-table {
    display: table;
    width: 98% !important;
  }

  .div-table-header {
    display: table-header-group;
  }

  .div-table-border {
    display: table;
    width: 98% !important;
    border-top: 1px solid #777;
    border-left: 1px solid #777;
    border-right: 1px solid #777;
  }

  .div-table-row {
    display: table-row;
  }

  .div-table-cell {
    display: table-cell;
    padding: 0 2pt;
    page-break-inside: avoid !important;
    page-break-after: auto;
    page-break-before: auto;
  }

  .div-table-cell-border {
    display: table-cell;
    border-bottom: 1px solid #777;
    padding: 2pt;
    page-break-inside: avoid !important;
    page-break-after: auto;
    page-break-before: auto;
  }

  header > .div-table-cell,
  header > .div-table-cell-border {
    font-weight: bold;
    text-transform: capitalize;
  }

  .avoid-break {
    display: block;
    position: relative;
    /*min-height: 40pt !important;*/
    /*height: 100% !important;*/
    page-break-inside: avoid !important;
  }
 /*
  th>div.avoid-break::before,
  td>div.avoid-break::before,
  th>div.avoid-break::after,
  td>div.avoid-break::after {
    content: "";
    height: 2pt;
    display: block;
    page-break-inside: avoid !important;
  }
*/
  table {
    width: 100% !important;
    border-collapse: collapse !important;
    /* works but not sure want to cuttoff at tables
    page-break-inside: avoid !important;
    position:relative;
    */
  }

  th,
  td{
    border: 1px solid #777 !important;
    min-width: 1pt;
    max-width: 100%;
    overflow-wrap: break-word;
  }

  th {
    padding: 0 2pt;
    font-weight: bold;
    text-transform: capitalize;
  }

  td {
    padding: 2pt;
  }

  md-toast {
    display: none;
  }

  md-tooltip > .md-content {
    display: none !important;
  }

  .layout, .layout-column, .layout-row {
    display: block;
  }

  .md-dialog-container {
    display: block;
    position: relative !important;
  }

  .print-hidden {
    display: none !important;
  }

  .print-version:not(.ng-hide) {
    display: block !important;
  }

  .print-record:not(.ng-hide) {
    visibility: visible !important;
    display: block !important;
  }

  .print-record * {
    visibility: visible !important;
  }

  .print-concepts thead th {
    text-align: left;
  }

  .print-concepts tbody td {
    vertical-align: top;
    white-space: normal;
  }

  .div-table-cell-border > span > img,
  .print-concepts tbody td img,
  .print-concepts tbody td i.material-icons {
    height: 12px !important;
  }

  .print-concepts vhr-footnotes ol {
    list-style-position: outside;
  }

  .print-concepts vhr-footnotes li {
    display: list-item;
    white-space: pre-wrap;
  }

  /* Customer header */

  .print-header {
    margin: 0 0 5pt 0;
  }

  .print-key {
    font-weight: bold;
  }

  .print-label {
    font-weight: bold;
    text-transform: capitalize;
  }

  .print-label:after {
    content: ": ";
  }

  .print-columnar {
    display: inline-block;
    vertical-align: top;
  }

  .print-columnar .print-label {
    width: 33% !important;
  }

  /* Patient header */

  .print-patient-header {
    margin: 0 0 10pt 0;
    border-bottom: 1pt solid #000;
    border-top: 1pt solid #000;
    padding: 5pt 0;
  }

  .print-patient-header > div {
    display: inline !important;
    margin: 0 10pt 0 0;
  }

  .print-patient-header > .patient-full-name {
    font-weight: bold;
  }

  /* Behavioral Health Data */
  .print-bh-div {
    .print-bh-message {
      margin: 0 0 10pt 0;
    }
    .print-bh-watermark {
      position: fixed !important;
      z-index: 1;
      left: 115px;
      right: 0;
      text{
        font-size: 40px;
        opacity: 0.1;
      }
    }
  }
  /* Record header */

  .print-record-header {
    margin: 5pt 0;
  }

  .print-record-header > .record-type-header::first-letter {
    text-transform: uppercase;
  }

  .print-record-header > .record-type-header {
    font-size: 10pt;
    font-weight: bold;
    margin: 5pt 0 0 0;
  }

  .print-concepts {
    margin: 5pt 0;
  }

  /* Original document */

  .original-document {
    font-size: 10pt;
    font-family: monospace;
    margin: 5pt 0;
  }

  .section-name {
    font-size: 10pt;
    font-weight: normal !important;
    text-decoration: underline;
    padding: 0;
  }

  /* Patient details */

  .print-detail-columns {
    display: flex;
    .print-detail-column:nth-child(2) {
      padding-left: 15px;
    }
  }

  .print-patient-details {
    visibility: visible !important;
    display: block;
  }

  .print-patient-details * {
    visibility: visible !important;
  }

  .print-title {
    margin: 5pt 0;
    text-decoration: underline;
    text-transform: capitalize;
    page-break-before: auto;
    page-break-after: avoid !important;
  }

  .print-inner-content {
    margin: 0 0 0 10pt;
  }

  .concept-notes {
    margin: 5pt 0;
  }

  .record-data-row {
    padding: 0px;
  }

  /* CCDA viewer */
  md-card {
    margin: 0;
    display: block;
  }

  md-card md-card-content {
    padding: 0;
  }

  .patient-name {
    display: none;
  }

  .cda-render .section, .cda-render .header {
    margin: 0 0 12pt 0;
  }

  .cda-render .section-title {
    border-bottom: 0;
    font-size: 10pt;
    font-weight: bold;
    margin: 6pt 0;
    page-break-before: auto;
    page-break-after: avoid !important;
  }

  .cda-render .section-subtitle {
    font-size: 10pt;
    font-weight: bold;
    margin: 4pt 0 2pt 0;
    text-transform: none;
    page-break-before: auto;
    page-break-after: avoid !important;
  }

  /* section content tables have h1 titles */
  h1.print-version {
    page-break-before: auto;
    page-break-after: avoid !important;
  }

  .cda-render table td,
  .cda-render table th {
    word-break: break-word;
    font-size: 8pt;
    padding: 0px;
    page-break-inside: avoid !important;
    page-break-after: auto;
    page-break-before: auto;
  }

/* ENG-1325 Reset scroll & sticky styles for print version, ENG-1587 add !important */
  .cda-render .table-responsive {
    max-height: unset !important;
  }
  .cda-render table th {
    position: unset;
    top: unset;
  }

  /* hiding the toc portion for print and setting flex-70 to all screen */
  #hie-document .toc {
    display: none;
  }
  #hie-document .flex-70, .layout-row>.flex-70 {
    max-width: inherit;
  }

  #main-header,
  #main-footer {
    display: none;
  }

}
