/*************************************/
/* Angular */
/*************************************/

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
          display: none !important;
}

/*************************************/
/* Notifications */
/*************************************/

md-toast .md-toast-content {
  padding: 15px;
}

/* Fixing positioning of toast */
md-toast {
  position: fixed;
}

md-toast.notification-toast-success {
  .md-toast-content {
    background-color: #689F38;
  }
}

md-toast.notification-toast-warn {
  .md-toast-content {
    background-color: #FFC107;
    color: #333;
  }
}

md-toast.notification-toast-error {
  .md-toast-content {
    background-color: #E53935;
  }
}

/* selected grid row background */
/* when row selected, bold text but keep row color the same */
/*.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
  font-weight: bold;
}
*/

.ui-grid-row:nth-child(odd).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
  background-color: #fdfdfd;
}
.ui-grid-row:nth-child(even).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
  background-color: #f3f3f3;
}

/* I want to select things from checkbox cell */
.ui-grid-cell.ui-grid-disable-selection.ui-grid-row-header-cell {
  pointer-events: auto;
}

.selectable .ui-grid-cell { cursor:pointer; }

.ui-grid-row-selected:hover.ui-grid-icon-ok:before {
  /*content: '\E15B' !important;*//*remove*/
}

.ui-grid-row-selected:hover > div > div.ui-grid-row-header-cell {
  background-color: rgba(0,0,0,0.87) !important;
}

.ui-grid-selection-row-header-buttons:hover {
  opacity: 1;
}

/* hover highlight color for grid rows */
.ui-grid-row:hover .ui-grid-cell {
  /*background-color: #BBDEFB !important;*/
  background-color: #FFF9C4 !important;
}

/* avoid button outline when in focus */
.ui-grid-icon-ok {
  outline: none;
}

/* attempted override but buggy
.ui-grid .ui-grid-render-container-body .ui-grid-viewport {
  overflow: auto !important;
}
.ui-grid-viewport {
  overflow-x: auto !important;
}*/

.ui-grid-pager-control input {
  height: 13px;
}

.ui-grid-pager-control .ui-grid-pager-max-pages-number {
  vertical-align: middle;
}

.ui-grid-pager-panel {
  background: #efefef;
}

/* ui-grid misc. */

.icon-left {
  float: left;
  padding: 5px 5px 0 0;
}

.icon-right-text {
  display: table-cell;
}

.icon-right {
  display: table-cell;
  vertical-align: middle;
}
