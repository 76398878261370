#vhr-app {
  .cda-render{
    font-size: 14px;
  }

  /* Document formatting */

  .cda-render li {
    list-style-type: none;
  }

  .cda-render .doc-title {
    padding: 0 0 18px 6px;
  }

  .cda-render .toc {
    min-width: 250px;
    /* ENG-1325 give toc menu max width */
    max-width: 380px;
  }

  .cda-render .panel-header {
    line-height: 40px;
    font-size: 16px;
    background: #1565c0;
    color: #fff;
  }

  .cda-render .panel-header-icon {
    color: #fff;
  }

  .cda-render .patient-name {
    font-size: 20px;
    font-weight: 400;
    color: #444;
    margin: 10px 0 20px 0;
  }

  .cda-render .section {
    margin: 0 0 40px 0;
  }

  .cda-render .header {
    margin: 0 0 40px 0;
  }

  .cda-render .list-item-section {
    text-transform: uppercase;
  }

  .cda-render .list-item-icon {
    padding-right: 8px;
    color: #666;
  }

  .cda-render .section-title {
    font-size: 16px;
    margin: 8pt 0;
    color: #666;
    font-weight: normal;
    text-transform: uppercase;
    page-break-before: auto;
    page-break-after: avoid !important;
    border-bottom: 1px solid #ccc;
  }

  .cda-render .section-subtitle {
    font-size: 12px;
    margin: 12pt 0 2pt 0;
    color: #888;
    text-transform: uppercase;
    page-break-before: auto;
    page-break-after: avoid !important;
  }

  /* UI-2706 - fixes large tables display */
  #hie-document md-card md-card-content {
    overflow: auto;
  }

  .cda-render .section-content {
    //margin-left:1em;
    //padding-left:0.5em;
  }

  .cda-render .section-row {
    display: block;
  }

  .cda-render .attribute-title {
    color: #888;
    text-transform: uppercase;
    display: inline;
  }

  .cda-render .attribute-value {
    display: inline;
  }

  .cda-render .generated-text{
    white-space:no-wrap;
    margin:0em;
    color:#333;
    font-style:italic;
  }

  /***** Header Grouping */

  .cda-render .tight{
    margin:0;
  }

  #navbar-list-cda {
    overflow: auto;
  }

  /****** TABLE *******/
  .cda-render table {
    /* ENG-1325 fix border to be on th/td */
    border-collapse: separate;
    border-spacing: 0;
    border: 0px;
    min-width: 400px;
    width: 100%;
  }

  .cda-render table th {
    background-color: #f3f3f3;
    color: rgba(0,0,0,.87);
    line-height: 18px;
    font-weight: 400;
    text-transform: uppercase;
    /* ENG-1325 Setup table headers to be sticky */
    position: sticky;
    top: 0;
  }

  .cda-render table td {
    color: rgba(0, 0, 0, 0.87);
  }

  .cda-render table td,
  .cda-render table th {
    border: 1px #ccc solid;
    border-collapse: collapse;
    vertical-align: middle;
    text-align: left;
    /* ENG-1325 reduce cell padding */
    padding: 4px;
  }

  .cda-render table td > *,
  .cda-render table th > * {
    vertical-align: middle;
  }

  /* ENG-1325 set tables to scroll if get "big" */
  .cda-render .table-responsive {
    max-height: 400px;
    width: 100%;
    overflow: auto;
  }

  /* ENG-1325 unset scroll on nested tables */
  .cda-render .table-responsive .table-responsive {
    max-height: unset;
  }

  /* VHR CCD RECORD DETAIL VIEW */

  #ccd-record-detail .cda-render .panel-header {
    line-height: 40px;
    font-size: 16px;
    background: #f3f3f3;
    color: rgba(0, 0, 0, 0.87);
    border-bottom: 1px solid #ccc;
  }

  #ccd-record-detail .cda-render .panel-header-icon {
    color: rgba(0,0,0,0.54);
  }

  /* MISC */

  /* ENG-1325 adjust flex layout */
  .cda-main {
    max-width: unset !important;
  }

}
