#sharing-app {

  .current-access-list {
    md-list {
      overflow-y: auto;
      overflow-x: hidden;
      md-list-item {
        height: 28px;
        min-height: 24px;
        md-icon {
          margin: 0 16px 0 0;
        }
      }
      span {
        font-size: 12px;
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  [ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
  }

  .none-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      md-icon {
        padding-right: 8px;
      }
    }
  }

  .sharing-list {
    .sharing-list-virtual-container {
      height: 610px;
      padding: 0;
    }
  }

  .share {
    margin-top: 8px;
  }

  .edit-access-name-column {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .edit-access-permission-columns {
    div {
      margin-bottom: 10px;
      p {
        margin: 0 0 12px 0;
      }
    }
  }

  .record-name {
    text-align: left;
    margin-left: 16px;
  }

  .record-owner {
    text-align: right;
    margin-right: 16px;
  }

  .record-label {
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(0, 0, 0, .51);
    margin-left: 16px;
  }

  .md-toolbar-tools input {
    outline:none;
    background: transparent;
  }

  md-card-header {
    line-height:36px;
  }

  table.md-table th.md-column,
  table.md-table td.md-cell {
    min-width: 100px;
    max-width: 100px;
    font-size: 14px;
    white-space: nowrap;
  }

  table.md-table th.md-checkbox-column,
  table.md-table td.md-checkbox-cell {
    min-width: 30px;
    max-width: 30px;
    text-overflow: initial;
  }

}
