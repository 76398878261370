table.md-table.hitlist-table {
  overflow: auto;
  position: relative;
  border-collapse: collapse;
}

.hitlist-table th {
  background: rgb(250, 250, 250);
  position: sticky;
  top: 0;
  z-index: 10;
}