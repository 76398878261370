#account-app {

  div[ui-view="main-content"] {
    position: relative;
    width: 100%;
    overflow-x: hidden;
  }

  .page-content {
    height: ~"calc(100% - 64px)"; //100% - height of toolbar
    overflow-y: auto;
  }

  .nav-content {
    height: ~"calc(100% - 49px)"; //100% - height of toolbar
    overflow-y: auto;
  }

  .overflow-auto {
    overflow-y: auto;
  }

  /*styles for vertical angular-ui tabs*/
  ul.nav-tabs.nav-stacked {
    float: left;
    width: 250px;
    margin-right: 10px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  ul.nav-stacked + div.tab-content {
    margin-left: 270px;
  }
  ul.nav-tabs.nav-stacked > li {

  }
  ul.nav-tabs.nav-stacked > li a {
    margin-right: 0;
    border-radius: 0;
  }

  #saveButton {
    float:right;
  }

  #saveBox {
      height: 30px;
  }

  .security-question-row {
    max-width: 700px;
    & > md-input-container {
      padding-top: 9px;
    }
    md-select {
      margin: 0;
      padding: 0;
    }
  }

  [key-value-table],
  .key-value-table {
    width: auto;
    min-width: 300px;
    margin: 8px 0;

    border-collapse: collapse;

    border: 1px solid rgba(0,0,0,.12);
    background-color: #f5f5f5;
    [row] {
      border: 1px solid rgba(0,0,0,.12);
      &:before {
        display: block;

        width: 0;
        height: 40px;

        content: '';
      }
      [key] {
        font-weight: bold;

        min-width: 100px;
        padding: 8px;

        text-align: right;

        background-color: #f5f5f5;
        label {
          text-align: left;
        }
      }
      [value] {
        min-width: 200px;
        padding: 8px;

        background-color: #fff;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px solid rgba(0,0,0,.12);
  }

  .reports-list md-checkbox {
    margin-bottom: 0;
  }

  [ng-messages].untouched {
    display: none;
  }

  md-input-container .md-errors-spacer {
    float: none;
    min-height: 0;
    min-width: 0;
  }

  //Styles for account home
  .account-home-content md-toolbar.page-header {
    background-color: transparent;
    color: black;
  }

  md-toolbar.page-header .page-header-content {
    box-sizing: border-box;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 64px;
    max-height: 64px;
    padding: 0 16px;
    margin: 0;
  }

  md-toolbar.page-header .page-header-content .label-value-row {
    display: inline-block;
  }

  md-list.link-list {
    padding: 0;
  }

  md-list-item.link-list-item .link-list-item-content {
    padding: 16px;
  }

  .link-list-item .link-title, .link-list-item .link-description {
    margin: 0;
    line-height: normal;
  }

  .link-list-item .link-title {
    font-weight: 400;
  }

  /* Styles for password & security */
  md-input-container.password-input {
    margin: 10px 0;
    width: 320px;
  }

  //Styles for account home
  .account-home-content md-toolbar.page-header {
    background-color: transparent;
    color: black;
  }

  md-toolbar.page-header .page-header-content {
    box-sizing: border-box;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 64px;
    max-height: 64px;
    padding: 0 16px;
    margin: 0;
  }

  md-toolbar.page-header .page-header-content .label-value-row {
    display: inline-block;
  }

  md-list.link-list {
    padding: 0;
  }

  md-list-item.link-list-item .link-list-item-content{
    padding: 16px;
  }

  .link-list-item .link-title, .link-list-item .link-description {
    margin: 0;
    line-height: normal;
  }

  .link-list-item .link-title {
    font-weight: 400;
  }

  /* Styles for password & security */
  md-input-container.password-input {
    margin: 10px 0;
    width: 320px;
  }

  //Styles for account home
  .account-home-content md-toolbar.page-header {
    background-color: transparent;
    color: black;
  }

  md-toolbar.page-header .page-header-content {
    box-sizing: border-box;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 64px;
    max-height: 64px;
    padding: 0 16px;
    margin: 0;
  }

  md-toolbar.page-header .page-header-content .label-value-row {
    display: inline-block;
  }

  md-list.link-list {
    padding: 0;
  }

  md-list-item.link-list-item .link-list-item-content {
    padding: 16px;
  }

  .link-list-item .link-title, .link-list-item .link-description {
    margin: 0;
    line-height: normal;
  }

  .link-list-item .link-title {
    font-weight: 400;
  }

}
