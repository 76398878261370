/*!
 *
 *  Project:  GLYPHICONS
 *  Author:   Jan Kovarik - www.glyphicons.com
 *  Twitter:  @jankovarik
 *
 */

// IMPORT FONTS
@font-face {
  font-family: 'Glyphicons Regular';
  src: url('@{fontBasePath}glyphicons-regular.eot');
  src: url('@{fontBasePath}glyphicons-regular.eot?#iefix') format('embedded-opentype'),
       url('@{fontBasePath}glyphicons-regular.woff') format('woff'),
       url('@{fontBasePath}glyphicons-regular.ttf') format('truetype'),
       url('@{fontBasePath}glyphicons-regular.svg#glyphiconsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}


// Catchall baseclass
.glyphicons {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  vertical-align:top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicons.x05{
  font-size:12px;
}
.glyphicons.x2{
  font-size:48px;
}
.glyphicons.x3{
  font-size:72px;
}
.glyphicons.x4{
  font-size:96px;
}
.glyphicons.x5{
  font-size:120px;
}


// Light
.glyphicons.light{
  &:before{
    color:#f2f2f2
  }
}

// Drop shadow
.glyphicons.drop{
  &:before{
    text-shadow: -1px 1px 3px rgba(0,0,0,0.3);
  }
}

// Horizontal Flip
.glyphicons.flip{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.glyphicons.flipv{
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

// Rotate
.glyphicons.rotate90{
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.glyphicons.rotate180{
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.glyphicons.rotate270{
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}


.glyphicons {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

  &.glyphicons-user{ &:before{ content:"\E004";} }
  &.glyphicons-power{ &:before{ content:"\E064";} }
  &.glyphicons-circle-question-mark{ &:before{ content:"\E195";} }
  .glyphicons-icon{
    display: inline-block;
    width: 48px;
    height: 48px;
    margin:0 8px 0 0;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url(../images/glyphicons.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    vertical-align: top;
    *display: inline;
    *zoom: 1;
    *margin-right: .3em;

    // OPERA BUG SVG FIX v9.5 - v12
    _:-o-prefocus, &{
      background-image: url(../images/glyphicons.png);
    }
    .no-inlinesvg &{
      background-image: url(../images/glyphicons.png);
    }
    &.white{
      background-image: url(../images/glyphicons-white.svg);

      // OPERA BUG SVG FIX v9.5 - v12
      _:-o-prefocus, &{
        background-image: url(../images/glyphicons-white.png);
      }
      .no-inlinesvg &{
        background-image: url(../images/glyphicons-white.png);
      }
    }
    &.glyphicons-user{ background-position: -140px 11px; }
    &.glyphicons-power{ background-position: -140px -277px; }
    &.glyphicons-circle-question-mark{ background-position: -188px -901px; }
  }
// FONT ICONS
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


  &.glyphicon-glass{ &:before{ content:"\E001";} }
  &.glyphicon-leaf{ &:before{ content:"\E002";} }
  &.glyphicon-dog{ &:before{ content:"\E003";} }
  &.glyphicon-user{ &:before{ content:"\E004";} }
  &.glyphicon-girl{ &:before{ content:"\E005";} }
  &.glyphicon-car{ &:before{ content:"\E006";} }
  &.glyphicon-user_add{ &:before{ content:"\E007";} }
  &.glyphicon-user_remove{ &:before{ content:"\E008";} }
  &.glyphicon-film{ &:before{ content:"\E009";} }
  &.glyphicon-magic{ &:before{ content:"\E010";} }
  &.glyphicon-envelope{ &:before{ content:"\2709";} }
  &.glyphicon-camera{ &:before{ content:"\E011";} }
  &.glyphicon-heart{ &:before{ content:"\E013";} }
  &.glyphicon-beach_umbrella{ &:before{ content:"\E014";} }
  &.glyphicon-train{ &:before{ content:"\E015";} }
  &.glyphicon-print{ &:before{ content:"\E016";} }
  &.glyphicon-bin{ &:before{ content:"\E017";} }
  &.glyphicon-music{ &:before{ content:"\E018";} }
  &.glyphicon-note{ &:before{ content:"\E019";} }
  &.glyphicon-heart_empty{ &:before{ content:"\E020";} }
  &.glyphicon-home{ &:before{ content:"\E021";} }
  &.glyphicon-snowflake{ &:before{ content:"\2744";} }
  &.glyphicon-fire{ &:before{ content:"\E023";} }
  &.glyphicon-magnet{ &:before{ content:"\E024";} }
  &.glyphicon-parents{ &:before{ content:"\E025";} }
  &.glyphicon-binoculars{ &:before{ content:"\E026";} }
  &.glyphicon-road{ &:before{ content:"\E027";} }
  &.glyphicon-search{ &:before{ content:"\E028";} }
  &.glyphicon-cars{ &:before{ content:"\E029";} }
  &.glyphicon-notes_2{ &:before{ content:"\E030";} }
  &.glyphicon-pencil{ &:before{ content:"\270F";} }
  &.glyphicon-bus{ &:before{ content:"\E032";} }
  &.glyphicon-wifi_alt{ &:before{ content:"\E033";} }
  &.glyphicon-luggage{ &:before{ content:"\E034";} }
  &.glyphicon-old_man{ &:before{ content:"\E035";} }
  &.glyphicon-woman{ &:before{ content:"\E036";} }
  &.glyphicon-file{ &:before{ content:"\E037";} }
  &.glyphicon-coins{ &:before{ content:"\E038";} }
  &.glyphicon-airplane{ &:before{ content:"\2708";} }
  &.glyphicon-notes{ &:before{ content:"\E040";} }
  &.glyphicon-stats{ &:before{ content:"\E041";} }
  &.glyphicon-charts{ &:before{ content:"\E042";} }
  &.glyphicon-pie_chart{ &:before{ content:"\E043";} }
  &.glyphicon-group{ &:before{ content:"\E044";} }
  &.glyphicon-keys{ &:before{ content:"\E045";} }
  &.glyphicon-calendar{ &:before{ content:"\E046";} }
  &.glyphicon-router{ &:before{ content:"\E047";} }
  &.glyphicon-camera_small{ &:before{ content:"\E048";} }
  &.glyphicon-dislikes{ &:before{ content:"\E049";} }
  &.glyphicon-star{ &:before{ content:"\E050";} }
  &.glyphicon-link{ &:before{ content:"\E051";} }
  &.glyphicon-eye_open{ &:before{ content:"\E052";} }
  &.glyphicon-eye_close{ &:before{ content:"\E053";} }
  &.glyphicon-alarm{ &:before{ content:"\E054";} }
  &.glyphicon-clock{ &:before{ content:"\E055";} }
  &.glyphicon-stopwatch{ &:before{ content:"\E056";} }
  &.glyphicon-projector{ &:before{ content:"\E057";} }
  &.glyphicon-history{ &:before{ content:"\E058";} }
  &.glyphicon-truck{ &:before{ content:"\E059";} }
  &.glyphicon-cargo{ &:before{ content:"\E060";} }
  &.glyphicon-compass{ &:before{ content:"\E061";} }
  &.glyphicon-keynote{ &:before{ content:"\E062";} }
  &.glyphicon-paperclip{ &:before{ content:"\E063";} }
  &.glyphicon-power{ &:before{ content:"\E064";} }
  &.glyphicon-lightbulb{ &:before{ content:"\E065";} }
  &.glyphicon-tag{ &:before{ content:"\E066";} }
  &.glyphicon-tags{ &:before{ content:"\E067";} }
  &.glyphicon-cleaning{ &:before{ content:"\E068";} }
  &.glyphicon-ruller{ &:before{ content:"\E069";} }
  &.glyphicon-gift{ &:before{ content:"\E070";} }
  &.glyphicon-umbrella{ &:before{ content:"\2602";} }
  &.glyphicon-book{ &:before{ content:"\E072";} }
  &.glyphicon-bookmark{ &:before{ content:"\E073";} }
  &.glyphicon-wifi{ &:before{ content:"\E074";} }
  &.glyphicon-cup{ &:before{ content:"\E075";} }
  &.glyphicon-stroller{ &:before{ content:"\E076";} }
  &.glyphicon-headphones{ &:before{ content:"\E077";} }
  &.glyphicon-headset{ &:before{ content:"\E078";} }
  &.glyphicon-warning_sign{ &:before{ content:"\E079";} }
  &.glyphicon-signal{ &:before{ content:"\E080";} }
  &.glyphicon-retweet{ &:before{ content:"\E081";} }
  &.glyphicon-refresh{ &:before{ content:"\E082";} }
  &.glyphicon-roundabout{ &:before{ content:"\E083";} }
  &.glyphicon-random{ &:before{ content:"\E084";} }
  &.glyphicon-heat{ &:before{ content:"\E085";} }
  &.glyphicon-repeat{ &:before{ content:"\E086";} }
  &.glyphicon-display{ &:before{ content:"\E087";} }
  &.glyphicon-log_book{ &:before{ content:"\E088";} }
  &.glyphicon-address_book{ &:before{ content:"\E089";} }
  &.glyphicon-building{ &:before{ content:"\E090";} }
  &.glyphicon-eyedropper{ &:before{ content:"\E091";} }
  &.glyphicon-adjust{ &:before{ content:"\E092";} }
  &.glyphicon-tint{ &:before{ content:"\E093";} }
  &.glyphicon-crop{ &:before{ content:"\E094";} }
  &.glyphicon-vector_path_square{ &:before{ content:"\E095";} }
  &.glyphicon-vector_path_circle{ &:before{ content:"\E096";} }
  &.glyphicon-vector_path_polygon{ &:before{ content:"\E097";} }
  &.glyphicon-vector_path_line{ &:before{ content:"\E098";} }
  &.glyphicon-vector_path_curve{ &:before{ content:"\E099";} }
  &.glyphicon-vector_path_all{ &:before{ content:"\E100";} }
  &.glyphicon-font{ &:before{ content:"\E101";} }
  &.glyphicon-italic{ &:before{ content:"\E102";} }
  &.glyphicon-bold{ &:before{ content:"\E103";} }
  &.glyphicon-text_underline{ &:before{ content:"\E104";} }
  &.glyphicon-text_strike{ &:before{ content:"\E105";} }
  &.glyphicon-text_height{ &:before{ content:"\E106";} }
  &.glyphicon-text_width{ &:before{ content:"\E107";} }
  &.glyphicon-text_resize{ &:before{ content:"\E108";} }
  &.glyphicon-left_indent{ &:before{ content:"\E109";} }
  &.glyphicon-right_indent{ &:before{ content:"\E110";} }
  &.glyphicon-align_left{ &:before{ content:"\E111";} }
  &.glyphicon-align_center{ &:before{ content:"\E112";} }
  &.glyphicon-align_right{ &:before{ content:"\E113";} }
  &.glyphicon-justify{ &:before{ content:"\E114";} }
  &.glyphicon-list{ &:before{ content:"\E115";} }
  &.glyphicon-text_smaller{ &:before{ content:"\E116";} }
  &.glyphicon-text_bigger{ &:before{ content:"\E117";} }
  &.glyphicon-embed{ &:before{ content:"\E118";} }
  &.glyphicon-embed_close{ &:before{ content:"\E119";} }
  &.glyphicon-table{ &:before{ content:"\E120";} }
  &.glyphicon-message_full{ &:before{ content:"\E121";} }
  &.glyphicon-message_empty{ &:before{ content:"\E122";} }
  &.glyphicon-message_in{ &:before{ content:"\E123";} }
  &.glyphicon-message_out{ &:before{ content:"\E124";} }
  &.glyphicon-message_plus{ &:before{ content:"\E125";} }
  &.glyphicon-message_minus{ &:before{ content:"\E126";} }
  &.glyphicon-message_ban{ &:before{ content:"\E127";} }
  &.glyphicon-message_flag{ &:before{ content:"\E128";} }
  &.glyphicon-message_lock{ &:before{ content:"\E129";} }
  &.glyphicon-message_new{ &:before{ content:"\E130";} }
  &.glyphicon-inbox{ &:before{ content:"\E131";} }
  &.glyphicon-inbox_plus{ &:before{ content:"\E132";} }
  &.glyphicon-inbox_minus{ &:before{ content:"\E133";} }
  &.glyphicon-inbox_lock{ &:before{ content:"\E134";} }
  &.glyphicon-inbox_in{ &:before{ content:"\E135";} }
  &.glyphicon-inbox_out{ &:before{ content:"\E136";} }
  &.glyphicon-cogwheel{ &:before{ content:"\E137";} }
  &.glyphicon-cogwheels{ &:before{ content:"\E138";} }
  &.glyphicon-picture{ &:before{ content:"\E139";} }
  &.glyphicon-adjust_alt{ &:before{ content:"\E140";} }
  &.glyphicon-database_lock{ &:before{ content:"\E141";} }
  &.glyphicon-database_plus{ &:before{ content:"\E142";} }
  &.glyphicon-database_minus{ &:before{ content:"\E143";} }
  &.glyphicon-database_ban{ &:before{ content:"\E144";} }
  &.glyphicon-folder_open{ &:before{ content:"\E145";} }
  &.glyphicon-folder_plus{ &:before{ content:"\E146";} }
  &.glyphicon-folder_minus{ &:before{ content:"\E147";} }
  &.glyphicon-folder_lock{ &:before{ content:"\E148";} }
  &.glyphicon-folder_flag{ &:before{ content:"\E149";} }
  &.glyphicon-folder_new{ &:before{ content:"\E150";} }
  &.glyphicon-edit{ &:before{ content:"\E151";} }
  &.glyphicon-new_window{ &:before{ content:"\E152";} }
  &.glyphicon-check{ &:before{ content:"\E153";} }
  &.glyphicon-unchecked{ &:before{ content:"\E154";} }
  &.glyphicon-more_windows{ &:before{ content:"\E155";} }
  &.glyphicon-show_big_thumbnails{ &:before{ content:"\E156";} }
  &.glyphicon-show_thumbnails{ &:before{ content:"\E157";} }
  &.glyphicon-show_thumbnails_with_lines{ &:before{ content:"\E158";} }
  &.glyphicon-show_lines{ &:before{ content:"\E159";} }
  &.glyphicon-playlist{ &:before{ content:"\E160";} }
  &.glyphicon-imac{ &:before{ content:"\E161";} }
  &.glyphicon-macbook{ &:before{ content:"\E162";} }
  &.glyphicon-ipad{ &:before{ content:"\E163";} }
  &.glyphicon-iphone{ &:before{ content:"\E164";} }
  &.glyphicon-iphone_transfer{ &:before{ content:"\E165";} }
  &.glyphicon-iphone_exchange{ &:before{ content:"\E166";} }
  &.glyphicon-ipod{ &:before{ content:"\E167";} }
  &.glyphicon-ipod_shuffle{ &:before{ content:"\E168";} }
  &.glyphicon-ear_plugs{ &:before{ content:"\E169";} }
  &.glyphicon-record{ &:before{ content:"\E170";} }
  &.glyphicon-step_backward{ &:before{ content:"\E171";} }
  &.glyphicon-fast_backward{ &:before{ content:"\E172";} }
  &.glyphicon-rewind{ &:before{ content:"\E173";} }
  &.glyphicon-play{ &:before{ content:"\E174";} }
  &.glyphicon-pause{ &:before{ content:"\E175";} }
  &.glyphicon-stop{ &:before{ content:"\E176";} }
  &.glyphicon-forward{ &:before{ content:"\E177";} }
  &.glyphicon-fast_forward{ &:before{ content:"\E178";} }
  &.glyphicon-step_forward{ &:before{ content:"\E179";} }
  &.glyphicon-eject{ &:before{ content:"\E180";} }
  &.glyphicon-facetime_video{ &:before{ content:"\E181";} }
  &.glyphicon-download_alt{ &:before{ content:"\E182";} }
  &.glyphicon-mute{ &:before{ content:"\E183";} }
  &.glyphicon-volume_down{ &:before{ content:"\E184";} }
  &.glyphicon-volume_up{ &:before{ content:"\E185";} }
  &.glyphicon-screenshot{ &:before{ content:"\E186";} }
  &.glyphicon-move{ &:before{ content:"\E187";} }
  &.glyphicon-more{ &:before{ content:"\E188";} }
  &.glyphicon-brightness_reduce{ &:before{ content:"\E189";} }
  &.glyphicon-brightness_increase{ &:before{ content:"\E190";} }
  &.glyphicon-circle_plus{ &:before{ content:"\E191";} }
  &.glyphicon-circle_minus{ &:before{ content:"\E192";} }
  &.glyphicon-circle_remove{ &:before{ content:"\E193";} }
  &.glyphicon-circle_ok{ &:before{ content:"\E194";} }
  &.glyphicon-circle_question_mark{ &:before{ content:"\E195";} }
  &.glyphicon-circle_info{ &:before{ content:"\E196";} }
  &.glyphicon-circle_exclamation_mark{ &:before{ content:"\E197";} }
  &.glyphicon-remove{ &:before{ content:"\E198";} }
  &.glyphicon-ok{ &:before{ content:"\E199";} }
  &.glyphicon-ban{ &:before{ content:"\E200";} }
  &.glyphicon-download{ &:before{ content:"\E201";} }
  &.glyphicon-upload{ &:before{ content:"\E202";} }
  &.glyphicon-shopping_cart{ &:before{ content:"\E203";} }
  &.glyphicon-lock{ &:before{ content:"\E204";} }
  &.glyphicon-unlock{ &:before{ content:"\E205";} }
  &.glyphicon-electricity{ &:before{ content:"\E206";} }
  &.glyphicon-ok_2{ &:before{ content:"\E207";} }
  &.glyphicon-remove_2{ &:before{ content:"\E208";} }
  &.glyphicon-cart_out{ &:before{ content:"\E209";} }
  &.glyphicon-cart_in{ &:before{ content:"\E210";} }
  &.glyphicon-left_arrow{ &:before{ content:"\E211";} }
  &.glyphicon-right_arrow{ &:before{ content:"\E212";} }
  &.glyphicon-down_arrow{ &:before{ content:"\E213";} }
  &.glyphicon-up_arrow{ &:before{ content:"\E214";} }
  &.glyphicon-resize_small{ &:before{ content:"\E215";} }
  &.glyphicon-resize_full{ &:before{ content:"\E216";} }
  &.glyphicon-circle_arrow_left{ &:before{ content:"\E217";} }
  &.glyphicon-circle_arrow_right{ &:before{ content:"\E218";} }
  &.glyphicon-circle_arrow_top{ &:before{ content:"\E219";} }
  &.glyphicon-circle_arrow_down{ &:before{ content:"\E220";} }
  &.glyphicon-play_button{ &:before{ content:"\E221";} }
  &.glyphicon-unshare{ &:before{ content:"\E222";} }
  &.glyphicon-share{ &:before{ content:"\E223";} }
  &.glyphicon-chevron-right{ &:before{ content:"\E224";} }
  &.glyphicon-chevron-left{ &:before{ content:"\E225";} }
  &.glyphicon-bluetooth{ &:before{ content:"\E226";} }
  &.glyphicon-euro{ &:before{ content:"\20AC";} }
  &.glyphicon-usd{ &:before{ content:"\E228";} }
  &.glyphicon-gbp{ &:before{ content:"\E229";} }
  &.glyphicon-retweet_2{ &:before{ content:"\E230";} }
  &.glyphicon-moon{ &:before{ content:"\E231";} }
  &.glyphicon-sun{ &:before{ content:"\2609";} }
  &.glyphicon-cloud{ &:before{ content:"\2601";} }
  &.glyphicon-direction{ &:before{ content:"\E234";} }
  &.glyphicon-brush{ &:before{ content:"\E235";} }
  &.glyphicon-pen{ &:before{ content:"\E236";} }
  &.glyphicon-zoom_in{ &:before{ content:"\E237";} }
  &.glyphicon-zoom_out{ &:before{ content:"\E238";} }
  &.glyphicon-pin{ &:before{ content:"\E239";} }
  &.glyphicon-albums{ &:before{ content:"\E240";} }
  &.glyphicon-rotation_lock{ &:before{ content:"\E241";} }
  &.glyphicon-flash{ &:before{ content:"\E242";} }
  &.glyphicon-google_maps{ &:before{ content:"\E243";} }
  &.glyphicon-anchor{ &:before{ content:"\2693";} }
  &.glyphicon-conversation{ &:before{ content:"\E245";} }
  &.glyphicon-chat{ &:before{ content:"\E246";} }
  &.glyphicon-male{ &:before{ content:"\E247";} }
  &.glyphicon-female{ &:before{ content:"\E248";} }
  &.glyphicon-asterisk{ &:before{ content:"\002A";} }
  &.glyphicon-divide{ &:before{ content:"\00F7";} }
  &.glyphicon-snorkel_diving{ &:before{ content:"\E251";} }
  &.glyphicon-scuba_diving{ &:before{ content:"\E252";} }
  &.glyphicon-oxygen_bottle{ &:before{ content:"\E253";} }
  &.glyphicon-fins{ &:before{ content:"\E254";} }
  &.glyphicon-fishes{ &:before{ content:"\E255";} }
  &.glyphicon-boat{ &:before{ content:"\E256";} }
  &.glyphicon-delete{ &:before{ content:"\E257";} }
  &.glyphicon-sheriffs_star{ &:before{ content:"\E258";} }
  &.glyphicon-qrcode{ &:before{ content:"\E259";} }
  &.glyphicon-barcode{ &:before{ content:"\E260";} }
  &.glyphicon-pool{ &:before{ content:"\E261";} }
  &.glyphicon-buoy{ &:before{ content:"\E262";} }
  &.glyphicon-spade{ &:before{ content:"\E263";} }
  &.glyphicon-bank{ &:before{ content:"\E264";} }
  &.glyphicon-vcard{ &:before{ content:"\E265";} }
  &.glyphicon-electrical_plug{ &:before{ content:"\E266";} }
  &.glyphicon-flag{ &:before{ content:"\E267";} }
  &.glyphicon-credit_card{ &:before{ content:"\E268";} }
  &.glyphicon-keyboard-wireless{ &:before{ content:"\E269";} }
  &.glyphicon-keyboard-wired{ &:before{ content:"\E270";} }
  &.glyphicon-shield{ &:before{ content:"\E271";} }
  &.glyphicon-ring{ &:before{ content:"\02DA";} }
  &.glyphicon-cake{ &:before{ content:"\E273";} }
  &.glyphicon-drink{ &:before{ content:"\E274";} }
  &.glyphicon-beer{ &:before{ content:"\E275";} }
  &.glyphicon-fast_food{ &:before{ content:"\E276";} }
  &.glyphicon-cutlery{ &:before{ content:"\E277";} }
  &.glyphicon-pizza{ &:before{ content:"\E278";} }
  &.glyphicon-birthday_cake{ &:before{ content:"\E279";} }
  &.glyphicon-tablet{ &:before{ content:"\E280";} }
  &.glyphicon-settings{ &:before{ content:"\E281";} }
  &.glyphicon-bullets{ &:before{ content:"\E282";} }
  &.glyphicon-cardio{ &:before{ content:"\E283";} }
  &.glyphicon-t-shirt{ &:before{ content:"\E284";} }
  &.glyphicon-pants{ &:before{ content:"\E285";} }
  &.glyphicon-sweater{ &:before{ content:"\E286";} }
  &.glyphicon-fabric{ &:before{ content:"\E287";} }
  &.glyphicon-leather{ &:before{ content:"\E288";} }
  &.glyphicon-scissors{ &:before{ content:"\E289";} }
  &.glyphicon-bomb{ &:before{ content:"\E290";} }
  &.glyphicon-skull{ &:before{ content:"\E291";} }
  &.glyphicon-celebration{ &:before{ content:"\E292";} }
  &.glyphicon-tea_kettle{ &:before{ content:"\E293";} }
  &.glyphicon-french_press{ &:before{ content:"\E294";} }
  &.glyphicon-coffee_cup{ &:before{ content:"\E295";} }
  &.glyphicon-pot{ &:before{ content:"\E296";} }
  &.glyphicon-grater{ &:before{ content:"\E297";} }
  &.glyphicon-kettle{ &:before{ content:"\E298";} }
  &.glyphicon-hospital{ &:before{ content:"\E299";} }
  &.glyphicon-hospital_h{ &:before{ content:"\E300";} }
  &.glyphicon-microphone{ &:before{ content:"\E301";} }
  &.glyphicon-webcam{ &:before{ content:"\E302";} }
  &.glyphicon-temple_christianity_church{ &:before{ content:"\E303";} }
  &.glyphicon-temple_islam{ &:before{ content:"\E304";} }
  &.glyphicon-temple_hindu{ &:before{ content:"\E305";} }
  &.glyphicon-temple_buddhist{ &:before{ content:"\E306";} }
  &.glyphicon-bicycle{ &:before{ content:"\E307";} }
  &.glyphicon-life_preserver{ &:before{ content:"\E308";} }
  &.glyphicon-share_alt{ &:before{ content:"\E309";} }
  &.glyphicon-comments{ &:before{ content:"\E310";} }
  &.glyphicon-flower{ &:before{ content:"\2698";} }
  &.glyphicon-baseball{ &:before{ content:"\26BE";} }
  &.glyphicon-rugby{ &:before{ content:"\E313";} }
  &.glyphicon-ax{ &:before{ content:"\E314";} }
  &.glyphicon-table_tennis{ &:before{ content:"\E315";} }
  &.glyphicon-bowling{ &:before{ content:"\E316";} }
  &.glyphicon-tree_conifer{ &:before{ content:"\E317";} }
  &.glyphicon-tree_deciduous{ &:before{ content:"\E318";} }
  &.glyphicon-more_items{ &:before{ content:"\E319";} }
  &.glyphicon-sort{ &:before{ content:"\E320";} }
  &.glyphicon-filter{ &:before{ content:"\E321";} }
  &.glyphicon-gamepad{ &:before{ content:"\E322";} }
  &.glyphicon-playing_dices{ &:before{ content:"\E323";} }
  &.glyphicon-calculator{ &:before{ content:"\E324";} }
  &.glyphicon-tie{ &:before{ content:"\E325";} }
  &.glyphicon-wallet{ &:before{ content:"\E326";} }
  &.glyphicon-piano{ &:before{ content:"\E327";} }
  &.glyphicon-sampler{ &:before{ content:"\E328";} }
  &.glyphicon-podium{ &:before{ content:"\E329";} }
  &.glyphicon-soccer_ball{ &:before{ content:"\E330";} }
  &.glyphicon-blog{ &:before{ content:"\E331";} }
  &.glyphicon-dashboard{ &:before{ content:"\E332";} }
  &.glyphicon-certificate{ &:before{ content:"\E333";} }
  &.glyphicon-bell{ &:before{ content:"\E334";} }
  &.glyphicon-candle{ &:before{ content:"\E335";} }
  &.glyphicon-pushpin{ &:before{ content:"\E336";} }
  &.glyphicon-iphone_shake{ &:before{ content:"\E337";} }
  &.glyphicon-pin_flag{ &:before{ content:"\E338";} }
  &.glyphicon-turtle{ &:before{ content:"\E339";} }
  &.glyphicon-rabbit{ &:before{ content:"\E340";} }
  &.glyphicon-globe{ &:before{ content:"\E341";} }
  &.glyphicon-briefcase{ &:before{ content:"\E342";} }
  &.glyphicon-hdd{ &:before{ content:"\E343";} }
  &.glyphicon-thumbs_up{ &:before{ content:"\E344";} }
  &.glyphicon-thumbs_down{ &:before{ content:"\E345";} }
  &.glyphicon-hand_right{ &:before{ content:"\E346";} }
  &.glyphicon-hand_left{ &:before{ content:"\E347";} }
  &.glyphicon-hand_up{ &:before{ content:"\E348";} }
  &.glyphicon-hand_down{ &:before{ content:"\E349";} }
  &.glyphicon-fullscreen{ &:before{ content:"\E350";} }
  &.glyphicon-shopping_bag{ &:before{ content:"\E351";} }
  &.glyphicon-book_open{ &:before{ content:"\E352";} }
  &.glyphicon-nameplate{ &:before{ content:"\E353";} }
  &.glyphicon-nameplate_alt{ &:before{ content:"\E354";} }
  &.glyphicon-vases{ &:before{ content:"\E355";} }
  &.glyphicon-bullhorn{ &:before{ content:"\E356";} }
  &.glyphicon-dumbbell{ &:before{ content:"\E357";} }
  &.glyphicon-suitcase{ &:before{ content:"\E358";} }
  &.glyphicon-file_import{ &:before{ content:"\E359";} }
  &.glyphicon-file_export{ &:before{ content:"\E360";} }
  &.glyphicon-bug{ &:before{ content:"\E361";} }
  &.glyphicon-crown{ &:before{ content:"\E362";} }
  &.glyphicon-smoking{ &:before{ content:"\E363";} }
  &.glyphicon-cloud-download{ &:before{ content:"\E364";} }
  &.glyphicon-cloud-upload{ &:before{ content:"\E365";} }
  &.glyphicon-restart{ &:before{ content:"\E366";} }
  &.glyphicon-security_camera{ &:before{ content:"\E367";} }
  &.glyphicon-expand{ &:before{ content:"\E368";} }
  &.glyphicon-collapse{ &:before{ content:"\E369";} }
  &.glyphicon-collapse_top{ &:before{ content:"\E370";} }
  &.glyphicon-globe_af{ &:before{ content:"\E371";} }
  &.glyphicon-global{ &:before{ content:"\E372";} }
  &.glyphicon-spray{ &:before{ content:"\E373";} }
  &.glyphicon-nails{ &:before{ content:"\E374";} }
  &.glyphicon-claw_hammer{ &:before{ content:"\E375";} }
  &.glyphicon-classic_hammer{ &:before{ content:"\E376";} }
  &.glyphicon-hand_saw{ &:before{ content:"\E377";} }
  &.glyphicon-riflescope{ &:before{ content:"\E378";} }
  &.glyphicon-electrical_socket_eu{ &:before{ content:"\E379";} }
  &.glyphicon-electrical_socket_us{ &:before{ content:"\E380";} }
  &.glyphicon-message_forward{ &:before{ content:"\E381";} }
  &.glyphicon-coat_hanger{ &:before{ content:"\E382";} }
  &.glyphicon-dress{ &:before{ content:"\E383";} }
  &.glyphicon-bathrobe{ &:before{ content:"\E384";} }
  &.glyphicon-shirt{ &:before{ content:"\E385";} }
  &.glyphicon-underwear{ &:before{ content:"\E386";} }
  &.glyphicon-log_in{ &:before{ content:"\E387";} }
  &.glyphicon-log_out{ &:before{ content:"\E388";} }
  &.glyphicon-exit{ &:before{ content:"\E389";} }
  &.glyphicon-new_window_alt{ &:before{ content:"\E390";} }
  &.glyphicon-video_sd{ &:before{ content:"\E391";} }
  &.glyphicon-video_hd{ &:before{ content:"\E392";} }
  &.glyphicon-subtitles{ &:before{ content:"\E393";} }
  &.glyphicon-sound_stereo{ &:before{ content:"\E394";} }
  &.glyphicon-sound_dolby{ &:before{ content:"\E395";} }
  &.glyphicon-sound_5_1{ &:before{ content:"\E396";} }
  &.glyphicon-sound_6_1{ &:before{ content:"\E397";} }
  &.glyphicon-sound_7_1{ &:before{ content:"\E398";} }
  &.glyphicon-copyright_mark{ &:before{ content:"\E399";} }
  &.glyphicon-registration_mark{ &:before{ content:"\E400";} }
  &.glyphicon-radar{ &:before{ content:"\E401";} }
  &.glyphicon-skateboard{ &:before{ content:"\E402";} }
  &.glyphicon-golf_course{ &:before{ content:"\E403";} }
  &.glyphicon-sorting{ &:before{ content:"\E404";} }
  &.glyphicon-sort-by-alphabet{ &:before{ content:"\E405";} }
  &.glyphicon-sort-by-alphabet-alt{ &:before{ content:"\E406";} }
  &.glyphicon-sort-by-order{ &:before{ content:"\E407";} }
  &.glyphicon-sort-by-order-alt{ &:before{ content:"\E408";} }
  &.glyphicon-sort-by-attributes{ &:before{ content:"\E409";} }
  &.glyphicon-sort-by-attributes-alt{ &:before{ content:"\E410";} }
  &.glyphicon-compressed{ &:before{ content:"\E411";} }
  &.glyphicon-package{ &:before{ content:"\E412";} }
  &.glyphicon-cloud_plus{ &:before{ content:"\E413";} }
  &.glyphicon-cloud_minus{ &:before{ content:"\E414";} }
  &.glyphicon-disk_save{ &:before{ content:"\E415";} }
  &.glyphicon-disk_open{ &:before{ content:"\E416";} }
  &.glyphicon-disk_saved{ &:before{ content:"\E417";} }
  &.glyphicon-disk_remove{ &:before{ content:"\E418";} }
  &.glyphicon-disk_import{ &:before{ content:"\E419";} }
  &.glyphicon-disk_export{ &:before{ content:"\E420";} }
  &.glyphicon-tower{ &:before{ content:"\E421";} }
  &.glyphicon-send{ &:before{ content:"\E422";} }
  &.glyphicon-git_branch{ &:before{ content:"\E423";} }
  &.glyphicon-git_create{ &:before{ content:"\E424";} }
  &.glyphicon-git_private{ &:before{ content:"\E425";} }
  &.glyphicon-git_delete{ &:before{ content:"\E426";} }
  &.glyphicon-git_merge{ &:before{ content:"\E427";} }
  &.glyphicon-git_pull_request{ &:before{ content:"\E428";} }
  &.glyphicon-git_compare{ &:before{ content:"\E429";} }
  &.glyphicon-git_commit{ &:before{ content:"\E430";} }
  &.glyphicon-construction_cone{ &:before{ content:"\E431";} }
  &.glyphicon-shoe_steps{ &:before{ content:"\E432";} }
  &.glyphicon-plus{ &:before{ content:"\002B";} }
  &.glyphicon-minus{ &:before{ content:"\2212";} }
  &.glyphicon-redo{ &:before{ content:"\E435";} }
  &.glyphicon-undo{ &:before{ content:"\E436";} }
  &.glyphicon-golf{ &:before{ content:"\E437";} }
  &.glyphicon-hockey{ &:before{ content:"\E438";} }
  &.glyphicon-pipe{ &:before{ content:"\E439";} }
  &.glyphicon-wrench{ &:before{ content:"\E440";} }
  &.glyphicon-folder_closed{ &:before{ content:"\E441";} }
  &.glyphicon-phone_alt{ &:before{ content:"\E442";} }
  &.glyphicon-earphone{ &:before{ content:"\E443";} }
  &.glyphicon-floppy_disk{ &:before{ content:"\E444";} }
  &.glyphicon-floppy_saved{ &:before{ content:"\E445";} }
  &.glyphicon-floppy_remove{ &:before{ content:"\E446";} }
  &.glyphicon-floppy_save{ &:before{ content:"\E447";} }
  &.glyphicon-floppy_open{ &:before{ content:"\E448";} }
  &.glyphicon-translate{ &:before{ content:"\E449";} }
  &.glyphicon-fax{ &:before{ content:"\E450";} }
  &.glyphicon-factory{ &:before{ content:"\E451";} }
  &.glyphicon-shop_window{ &:before{ content:"\E452";} }
  &.glyphicon-shop{ &:before{ content:"\E453";} }
  &.glyphicon-kiosk{ &:before{ content:"\E454";} }
  &.glyphicon-kiosk_wheels{ &:before{ content:"\E455";} }
  &.glyphicon-kiosk_light{ &:before{ content:"\E456";} }
  &.glyphicon-kiosk_food{ &:before{ content:"\E457";} }
  &.glyphicon-transfer{ &:before{ content:"\E458";} }
  &.glyphicon-money{ &:before{ content:"\E459";} }
  &.glyphicon-header{ &:before{ content:"\E460";} }
  &.glyphicon-blacksmith{ &:before{ content:"\E461";} }
  &.glyphicon-saw_blade{ &:before{ content:"\E462";} }
  &.glyphicon-basketball{ &:before{ content:"\E463";} }
  &.glyphicon-server{ &:before{ content:"\E464";} }
  &.glyphicon-server_plus{ &:before{ content:"\E465";} }
  &.glyphicon-server_minus{ &:before{ content:"\E466";} }
  &.glyphicon-server_ban{ &:before{ content:"\E467";} }
  &.glyphicon-server_flag{ &:before{ content:"\E468";} }
  &.glyphicon-server_lock{ &:before{ content:"\E469";} }
  &.glyphicon-server_new{ &:before{ content:"\E470";} }
  &.glyphicon-menu_hamburger{ &:before{ content:"\E517";} }

// IMAGE ICONS
.glyphicon-icon{
  display: inline-block;
  width: 48px;
  height: 48px;
  margin:0 8px 0 0;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url(../images/glyphicons.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  vertical-align: top;
  *display: inline;
  *zoom: 1;
  *margin-right: .3em;

  // OPERA BUG SVG FIX v9.5 - v12
  _:-o-prefocus, &{
    background-image: url(../images/glyphicons.png);
  }
  .no-inlinesvg &{
    background-image: url(../images/glyphicons.png);
  }
  &.white{
    background-image: url(../images/glyphicons-white.svg);

    // OPERA BUG SVG FIX v9.5 - v12
    _:-o-prefocus, &{
      background-image: url(../images/glyphicons-white.png);
    }
    .no-inlinesvg &{
      background-image: url(../images/glyphicons-white.png);
    }
  }

  &.glyphicon-glass{ background-position: 4px 11px; }
  &.glyphicon-leaf{ background-position: -44px 11px; }
  &.glyphicon-dog{ background-position: -92px 11px; }
  &.glyphicon-user{ background-position: -140px 11px; }
  &.glyphicon-girl{ background-position: -188px 11px; }
  &.glyphicon-car{ background-position: -236px 11px; }
  &.glyphicon-user_add{ background-position: -284px 11px; }
  &.glyphicon-user_remove{ background-position: -332px 11px; }
  &.glyphicon-film{ background-position: -380px 11px; }
  &.glyphicon-magic{ background-position: -428px 11px; }
  &.glyphicon-envelope{ background-position: 4px -37px; }
  &.glyphicon-camera{ background-position: -44px -37px; }
  &.glyphicon-heart{ background-position: -92px -37px; }
  &.glyphicon-beach_umbrella{ background-position: -140px -37px; }
  &.glyphicon-train{ background-position: -188px -37px; }
  &.glyphicon-print{ background-position: -236px -37px; }
  &.glyphicon-bin{ background-position: -284px -37px; }
  &.glyphicon-music{ background-position: -332px -37px; }
  &.glyphicon-note{ background-position: -380px -37px; }
  &.glyphicon-heart_empty{ background-position: -428px -37px; }
  &.glyphicon-home{ background-position: 4px -85px; }
  &.glyphicon-snowflake{ background-position: -44px -85px; }
  &.glyphicon-fire{ background-position: -92px -85px; }
  &.glyphicon-magnet{ background-position: -140px -85px; }
  &.glyphicon-parents{ background-position: -188px -85px; }
  &.glyphicon-binoculars{ background-position: -236px -85px; }
  &.glyphicon-road{ background-position: -284px -85px; }
  &.glyphicon-search{ background-position: -332px -85px; }
  &.glyphicon-cars{ background-position: -380px -85px; }
  &.glyphicon-notes_2{ background-position: -428px -85px; }
  &.glyphicon-pencil{ background-position: 4px -133px; }
  &.glyphicon-bus{ background-position: -44px -133px; }
  &.glyphicon-wifi_alt{ background-position: -92px -133px; }
  &.glyphicon-luggage{ background-position: -140px -133px; }
  &.glyphicon-old_man{ background-position: -188px -133px; }
  &.glyphicon-woman{ background-position: -236px -133px; }
  &.glyphicon-file{ background-position: -284px -133px; }
  &.glyphicon-coins{ background-position: -332px -133px; }
  &.glyphicon-airplane{ background-position: -380px -133px; }
  &.glyphicon-notes{ background-position: -428px -133px; }
  &.glyphicon-stats{ background-position: 4px -181px; }
  &.glyphicon-charts{ background-position: -44px -181px; }
  &.glyphicon-pie_chart{ background-position: -92px -181px; }
  &.glyphicon-group{ background-position: -140px -181px; }
  &.glyphicon-keys{ background-position: -188px -181px; }
  &.glyphicon-calendar{ background-position: -236px -181px; }
  &.glyphicon-router{ background-position: -284px -181px; }
  &.glyphicon-camera_small{ background-position: -332px -181px; }
  &.glyphicon-dislikes{ background-position: -380px -181px; }
  &.glyphicon-star{ background-position: -428px -181px; }
  &.glyphicon-link{ background-position: 4px -229px; }
  &.glyphicon-eye_open{ background-position: -44px -229px; }
  &.glyphicon-eye_close{ background-position: -92px -229px; }
  &.glyphicon-alarm{ background-position: -140px -229px; }
  &.glyphicon-clock{ background-position: -188px -229px; }
  &.glyphicon-stopwatch{ background-position: -236px -229px; }
  &.glyphicon-projector{ background-position: -284px -229px; }
  &.glyphicon-history{ background-position: -332px -229px; }
  &.glyphicon-truck{ background-position: -380px -229px; }
  &.glyphicon-cargo{ background-position: -428px -229px; }
  &.glyphicon-compass{ background-position: 4px -277px; }
  &.glyphicon-keynote{ background-position: -44px -277px; }
  &.glyphicon-paperclip{ background-position: -92px -277px; }
  &.glyphicon-power{ background-position: -140px -277px; }
  &.glyphicon-lightbulb{ background-position: -188px -277px; }
  &.glyphicon-tag{ background-position: -236px -277px; }
  &.glyphicon-tags{ background-position: -284px -277px; }
  &.glyphicon-cleaning{ background-position: -332px -277px; }
  &.glyphicon-ruller{ background-position: -380px -277px; }
  &.glyphicon-gift{ background-position: -428px -277px; }
  &.glyphicon-umbrella{ background-position: 4px -325px; }
  &.glyphicon-book{ background-position: -44px -325px; }
  &.glyphicon-bookmark{ background-position: -92px -325px; }
  &.glyphicon-wifi{ background-position: -140px -325px; }
  &.glyphicon-cup{ background-position: -188px -325px; }
  &.glyphicon-stroller{ background-position: -236px -325px; }
  &.glyphicon-headphones{ background-position: -284px -325px; }
  &.glyphicon-headset{ background-position: -332px -325px; }
  &.glyphicon-warning_sign{ background-position: -380px -325px; }
  &.glyphicon-signal{ background-position: -428px -325px; }
  &.glyphicon-retweet{ background-position: 4px -373px; }
  &.glyphicon-refresh{ background-position: -44px -373px; }
  &.glyphicon-roundabout{ background-position: -92px -373px; }
  &.glyphicon-random{ background-position: -140px -373px; }
  &.glyphicon-heat{ background-position: -188px -373px; }
  &.glyphicon-repeat{ background-position: -236px -373px; }
  &.glyphicon-display{ background-position: -284px -373px; }
  &.glyphicon-log_book{ background-position: -332px -373px; }
  &.glyphicon-address_book{ background-position: -380px -373px; }
  &.glyphicon-building{ background-position: -428px -373px; }
  &.glyphicon-eyedropper{ background-position: 4px -421px; }
  &.glyphicon-adjust{ background-position: -44px -421px; }
  &.glyphicon-tint{ background-position: -92px -421px; }
  &.glyphicon-crop{ background-position: -140px -421px; }
  &.glyphicon-vector_path_square{ background-position: -188px -421px; }
  &.glyphicon-vector_path_circle{ background-position: -236px -421px; }
  &.glyphicon-vector_path_polygon{ background-position: -284px -421px; }
  &.glyphicon-vector_path_line{ background-position: -332px -421px; }
  &.glyphicon-vector_path_curve{ background-position: -380px -421px; }
  &.glyphicon-vector_path_all{ background-position: -428px -421px; }
  &.glyphicon-font{ background-position: 4px -469px; }
  &.glyphicon-italic{ background-position: -44px -469px; }
  &.glyphicon-bold{ background-position: -92px -469px; }
  &.glyphicon-text_underline{ background-position: -140px -469px; }
  &.glyphicon-text_strike{ background-position: -188px -469px; }
  &.glyphicon-text_height{ background-position: -236px -469px; }
  &.glyphicon-text_width{ background-position: -284px -469px; }
  &.glyphicon-text_resize{ background-position: -332px -469px; }
  &.glyphicon-left_indent{ background-position: -380px -469px; }
  &.glyphicon-right_indent{ background-position: -428px -469px; }
  &.glyphicon-align_left{ background-position: 4px -517px; }
  &.glyphicon-align_center{ background-position: -44px -517px; }
  &.glyphicon-align_right{ background-position: -92px -517px; }
  &.glyphicon-justify{ background-position: -140px -517px; }
  &.glyphicon-list{ background-position: -188px -517px; }
  &.glyphicon-text_smaller{ background-position: -236px -517px; }
  &.glyphicon-text_bigger{ background-position: -284px -517px; }
  &.glyphicon-embed{ background-position: -332px -517px; }
  &.glyphicon-embed_close{ background-position: -380px -517px; }
  &.glyphicon-table{ background-position: -428px -517px; }
  &.glyphicon-message_full{ background-position: 4px -565px; }
  &.glyphicon-message_empty{ background-position: -44px -565px; }
  &.glyphicon-message_in{ background-position: -92px -565px; }
  &.glyphicon-message_out{ background-position: -140px -565px; }
  &.glyphicon-message_plus{ background-position: -188px -565px; }
  &.glyphicon-message_minus{ background-position: -236px -565px; }
  &.glyphicon-message_ban{ background-position: -284px -565px; }
  &.glyphicon-message_flag{ background-position: -332px -565px; }
  &.glyphicon-message_lock{ background-position: -380px -565px; }
  &.glyphicon-message_new{ background-position: -428px -565px; }
  &.glyphicon-inbox{ background-position: 4px -613px; }
  &.glyphicon-inbox_plus{ background-position: -44px -613px; }
  &.glyphicon-inbox_minus{ background-position: -92px -613px; }
  &.glyphicon-inbox_lock{ background-position: -140px -613px; }
  &.glyphicon-inbox_in{ background-position: -188px -613px; }
  &.glyphicon-inbox_out{ background-position: -236px -613px; }
  &.glyphicon-cogwheel{ background-position: -284px -613px; }
  &.glyphicon-cogwheels{ background-position: -332px -613px; }
  &.glyphicon-picture{ background-position: -380px -613px; }
  &.glyphicon-adjust_alt{ background-position: -428px -613px; }
  &.glyphicon-database_lock{ background-position: 4px -661px; }
  &.glyphicon-database_plus{ background-position: -44px -661px; }
  &.glyphicon-database_minus{ background-position: -92px -661px; }
  &.glyphicon-database_ban{ background-position: -140px -661px; }
  &.glyphicon-folder_open{ background-position: -188px -661px; }
  &.glyphicon-folder_plus{ background-position: -236px -661px; }
  &.glyphicon-folder_minus{ background-position: -284px -661px; }
  &.glyphicon-folder_lock{ background-position: -332px -661px; }
  &.glyphicon-folder_flag{ background-position: -380px -661px; }
  &.glyphicon-folder_new{ background-position: -428px -661px; }
  &.glyphicon-edit{ background-position: 4px -709px; }
  &.glyphicon-new_window{ background-position: -44px -709px; }
  &.glyphicon-check{ background-position: -92px -709px; }
  &.glyphicon-unchecked{ background-position: -140px -709px; }
  &.glyphicon-more_windows{ background-position: -188px -709px; }
  &.glyphicon-show_big_thumbnails{ background-position: -236px -709px; }
  &.glyphicon-show_thumbnails{ background-position: -284px -709px; }
  &.glyphicon-show_thumbnails_with_lines{ background-position: -332px -709px; }
  &.glyphicon-show_lines{ background-position: -380px -709px; }
  &.glyphicon-playlist{ background-position: -428px -709px; }
  &.glyphicon-imac{ background-position: 4px -757px; }
  &.glyphicon-macbook{ background-position: -44px -757px; }
  &.glyphicon-ipad{ background-position: -92px -757px; }
  &.glyphicon-iphone{ background-position: -140px -757px; }
  &.glyphicon-iphone_transfer{ background-position: -188px -757px; }
  &.glyphicon-iphone_exchange{ background-position: -236px -757px; }
  &.glyphicon-ipod{ background-position: -284px -757px; }
  &.glyphicon-ipod_shuffle{ background-position: -332px -757px; }
  &.glyphicon-ear_plugs{ background-position: -380px -757px; }
  &.glyphicon-record{ background-position: -428px -757px; }
  &.glyphicon-step_backward{ background-position: 4px -805px; }
  &.glyphicon-fast_backward{ background-position: -44px -805px; }
  &.glyphicon-rewind{ background-position: -92px -805px; }
  &.glyphicon-play{ background-position: -140px -805px; }
  &.glyphicon-pause{ background-position: -188px -805px; }
  &.glyphicon-stop{ background-position: -236px -805px; }
  &.glyphicon-forward{ background-position: -284px -805px; }
  &.glyphicon-fast_forward{ background-position: -332px -805px; }
  &.glyphicon-step_forward{ background-position: -380px -805px; }
  &.glyphicon-eject{ background-position: -428px -805px; }
  &.glyphicon-facetime_video{ background-position: 4px -853px; }
  &.glyphicon-download_alt{ background-position: -44px -853px; }
  &.glyphicon-mute{ background-position: -92px -853px; }
  &.glyphicon-volume_down{ background-position: -140px -853px; }
  &.glyphicon-volume_up{ background-position: -188px -853px; }
  &.glyphicon-screenshot{ background-position: -236px -853px; }
  &.glyphicon-move{ background-position: -284px -853px; }
  &.glyphicon-more{ background-position: -332px -853px; }
  &.glyphicon-brightness_reduce{ background-position: -380px -853px; }
  &.glyphicon-brightness_increase{ background-position: -428px -853px; }
  &.glyphicon-circle_plus{ background-position: 4px -901px; }
  &.glyphicon-circle_minus{ background-position: -44px -901px; }
  &.glyphicon-circle_remove{ background-position: -92px -901px; }
  &.glyphicon-circle_ok{ background-position: -140px -901px; }
  &.glyphicon-circle_question_mark{ background-position: -188px -901px; }
  &.glyphicon-circle_info{ background-position: -236px -901px; }
  &.glyphicon-circle_exclamation_mark{ background-position: -284px -901px; }
  &.glyphicon-remove{ background-position: -332px -901px; }
  &.glyphicon-ok{ background-position: -380px -901px; }
  &.glyphicon-ban{ background-position: -428px -901px; }
  &.glyphicon-download{ background-position: 4px -949px; }
  &.glyphicon-upload{ background-position: -44px -949px; }
  &.glyphicon-shopping_cart{ background-position: -92px -949px; }
  &.glyphicon-lock{ background-position: -140px -949px; }
  &.glyphicon-unlock{ background-position: -188px -949px; }
  &.glyphicon-electricity{ background-position: -236px -949px; }
  &.glyphicon-ok_2{ background-position: -284px -949px; }
  &.glyphicon-remove_2{ background-position: -332px -949px; }
  &.glyphicon-cart_out{ background-position: -380px -949px; }
  &.glyphicon-cart_in{ background-position: -428px -949px; }
  &.glyphicon-left_arrow{ background-position: 4px -997px; }
  &.glyphicon-right_arrow{ background-position: -44px -997px; }
  &.glyphicon-down_arrow{ background-position: -92px -997px; }
  &.glyphicon-up_arrow{ background-position: -140px -997px; }
  &.glyphicon-resize_small{ background-position: -188px -997px; }
  &.glyphicon-resize_full{ background-position: -236px -997px; }
  &.glyphicon-circle_arrow_left{ background-position: -284px -997px; }
  &.glyphicon-circle_arrow_right{ background-position: -332px -997px; }
  &.glyphicon-circle_arrow_top{ background-position: -380px -997px; }
  &.glyphicon-circle_arrow_down{ background-position: -428px -997px; }
  &.glyphicon-play_button{ background-position: 4px -1045px; }
  &.glyphicon-unshare{ background-position: -44px -1045px; }
  &.glyphicon-share{ background-position: -92px -1045px; }
  &.glyphicon-chevron-right{ background-position: -140px -1045px; }
  &.glyphicon-chevron-left{ background-position: -188px -1045px; }
  &.glyphicon-bluetooth{ background-position: -236px -1045px; }
  &.glyphicon-euro{ background-position: -284px -1045px; }
  &.glyphicon-usd{ background-position: -332px -1045px; }
  &.glyphicon-gbp{ background-position: -380px -1045px; }
  &.glyphicon-retweet_2{ background-position: -428px -1045px; }
  &.glyphicon-moon{ background-position: 4px -1093px; }
  &.glyphicon-sun{ background-position: -44px -1093px; }
  &.glyphicon-cloud{ background-position: -92px -1093px; }
  &.glyphicon-direction{ background-position: -140px -1093px; }
  &.glyphicon-brush{ background-position: -188px -1093px; }
  &.glyphicon-pen{ background-position: -236px -1093px; }
  &.glyphicon-zoom_in{ background-position: -284px -1093px; }
  &.glyphicon-zoom_out{ background-position: -332px -1093px; }
  &.glyphicon-pin{ background-position: -380px -1093px; }
  &.glyphicon-albums{ background-position: -428px -1093px; }
  &.glyphicon-rotation_lock{ background-position: 4px -1141px; }
  &.glyphicon-flash{ background-position: -44px -1141px; }
  &.glyphicon-google_maps{ background-position: -92px -1141px; }
  &.glyphicon-anchor{ background-position: -140px -1141px; }
  &.glyphicon-conversation{ background-position: -188px -1141px; }
  &.glyphicon-chat{ background-position: -236px -1141px; }
  &.glyphicon-male{ background-position: -284px -1141px; }
  &.glyphicon-female{ background-position: -332px -1141px; }
  &.glyphicon-asterisk{ background-position: -380px -1141px; }
  &.glyphicon-divide{ background-position: -428px -1141px; }
  &.glyphicon-snorkel_diving{ background-position: 4px -1189px; }
  &.glyphicon-scuba_diving{ background-position: -44px -1189px; }
  &.glyphicon-oxygen_bottle{ background-position: -92px -1189px; }
  &.glyphicon-fins{ background-position: -140px -1189px; }
  &.glyphicon-fishes{ background-position: -188px -1189px; }
  &.glyphicon-boat{ background-position: -236px -1189px; }
  &.glyphicon-delete{ background-position: -284px -1189px; }
  &.glyphicon-sheriffs_star{ background-position: -332px -1189px; }
  &.glyphicon-qrcode{ background-position: -380px -1189px; }
  &.glyphicon-barcode{ background-position: -428px -1189px; }
  &.glyphicon-pool{ background-position: 4px -1237px; }
  &.glyphicon-buoy{ background-position: -44px -1237px; }
  &.glyphicon-spade{ background-position: -92px -1237px; }
  &.glyphicon-bank{ background-position: -140px -1237px; }
  &.glyphicon-vcard{ background-position: -188px -1237px; }
  &.glyphicon-electrical_plug{ background-position: -236px -1237px; }
  &.glyphicon-flag{ background-position: -284px -1237px; }
  &.glyphicon-credit_card{ background-position: -332px -1237px; }
  &.glyphicon-keyboard-wireless{ background-position: -380px -1237px; }
  &.glyphicon-keyboard-wired{ background-position: -428px -1237px; }
  &.glyphicon-shield{ background-position: 4px -1285px; }
  &.glyphicon-ring{ background-position: -44px -1285px; }
  &.glyphicon-cake{ background-position: -92px -1285px; }
  &.glyphicon-drink{ background-position: -140px -1285px; }
  &.glyphicon-beer{ background-position: -188px -1285px; }
  &.glyphicon-fast_food{ background-position: -236px -1285px; }
  &.glyphicon-cutlery{ background-position: -284px -1285px; }
  &.glyphicon-pizza{ background-position: -332px -1285px; }
  &.glyphicon-birthday_cake{ background-position: -380px -1285px; }
  &.glyphicon-tablet{ background-position: -428px -1285px; }
  &.glyphicon-settings{ background-position: 4px -1333px; }
  &.glyphicon-bullets{ background-position: -44px -1333px; }
  &.glyphicon-cardio{ background-position: -92px -1333px; }
  &.glyphicon-t-shirt{ background-position: -140px -1333px; }
  &.glyphicon-pants{ background-position: -188px -1333px; }
  &.glyphicon-sweater{ background-position: -236px -1333px; }
  &.glyphicon-fabric{ background-position: -284px -1333px; }
  &.glyphicon-leather{ background-position: -332px -1333px; }
  &.glyphicon-scissors{ background-position: -380px -1333px; }
  &.glyphicon-bomb{ background-position: -428px -1333px; }
  &.glyphicon-skull{ background-position: 4px -1381px; }
  &.glyphicon-celebration{ background-position: -44px -1381px; }
  &.glyphicon-tea_kettle{ background-position: -92px -1381px; }
  &.glyphicon-french_press{ background-position: -140px -1381px; }
  &.glyphicon-coffee_cup{ background-position: -188px -1381px; }
  &.glyphicon-pot{ background-position: -236px -1381px; }
  &.glyphicon-grater{ background-position: -284px -1381px; }
  &.glyphicon-kettle{ background-position: -332px -1381px; }
  &.glyphicon-hospital{ background-position: -380px -1381px; }
  &.glyphicon-hospital_h{ background-position: -428px -1381px; }
  &.glyphicon-microphone{ background-position: 4px -1429px; }
  &.glyphicon-webcam{ background-position: -44px -1429px; }
  &.glyphicon-temple_christianity_church{ background-position: -92px -1429px; }
  &.glyphicon-temple_islam{ background-position: -140px -1429px; }
  &.glyphicon-temple_hindu{ background-position: -188px -1429px; }
  &.glyphicon-temple_buddhist{ background-position: -236px -1429px; }
  &.glyphicon-bicycle{ background-position: -284px -1429px; }
  &.glyphicon-life_preserver{ background-position: -332px -1429px; }
  &.glyphicon-share_alt{ background-position: -380px -1429px; }
  &.glyphicon-comments{ background-position: -428px -1429px; }
  &.glyphicon-flower{ background-position: 4px -1477px; }
  &.glyphicon-baseball{ background-position: -44px -1477px; }
  &.glyphicon-rugby{ background-position: -92px -1477px; }
  &.glyphicon-ax{ background-position: -140px -1477px; }
  &.glyphicon-table_tennis{ background-position: -188px -1477px; }
  &.glyphicon-bowling{ background-position: -236px -1477px; }
  &.glyphicon-tree_conifer{ background-position: -284px -1477px; }
  &.glyphicon-tree_deciduous{ background-position: -332px -1477px; }
  &.glyphicon-more_items{ background-position: -380px -1477px; }
  &.glyphicon-sort{ background-position: -428px -1477px; }
  &.glyphicon-filter{ background-position: 4px -1525px; }
  &.glyphicon-gamepad{ background-position: -44px -1525px; }
  &.glyphicon-playing_dices{ background-position: -92px -1525px; }
  &.glyphicon-calculator{ background-position: -140px -1525px; }
  &.glyphicon-tie{ background-position: -188px -1525px; }
  &.glyphicon-wallet{ background-position: -236px -1525px; }
  &.glyphicon-piano{ background-position: -284px -1525px; }
  &.glyphicon-sampler{ background-position: -332px -1525px; }
  &.glyphicon-podium{ background-position: -380px -1525px; }
  &.glyphicon-soccer_ball{ background-position: -428px -1525px; }
  &.glyphicon-blog{ background-position: 4px -1573px; }
  &.glyphicon-dashboard{ background-position: -44px -1573px; }
  &.glyphicon-certificate{ background-position: -92px -1573px; }
  &.glyphicon-bell{ background-position: -140px -1573px; }
  &.glyphicon-candle{ background-position: -188px -1573px; }
  &.glyphicon-pushpin{ background-position: -236px -1573px; }
  &.glyphicon-iphone_shake{ background-position: -284px -1573px; }
  &.glyphicon-pin_flag{ background-position: -332px -1573px; }
  &.glyphicon-turtle{ background-position: -380px -1573px; }
  &.glyphicon-rabbit{ background-position: -428px -1573px; }
  &.glyphicon-globe{ background-position: 4px -1621px; }
  &.glyphicon-briefcase{ background-position: -44px -1621px; }
  &.glyphicon-hdd{ background-position: -92px -1621px; }
  &.glyphicon-thumbs_up{ background-position: -140px -1621px; }
  &.glyphicon-thumbs_down{ background-position: -188px -1621px; }
  &.glyphicon-hand_right{ background-position: -236px -1621px; }
  &.glyphicon-hand_left{ background-position: -284px -1621px; }
  &.glyphicon-hand_up{ background-position: -332px -1621px; }
  &.glyphicon-hand_down{ background-position: -380px -1621px; }
  &.glyphicon-fullscreen{ background-position: -428px -1621px; }
  &.glyphicon-shopping_bag{ background-position: 4px -1669px; }
  &.glyphicon-book_open{ background-position: -44px -1669px; }
  &.glyphicon-nameplate{ background-position: -92px -1669px; }
  &.glyphicon-nameplate_alt{ background-position: -140px -1669px; }
  &.glyphicon-vases{ background-position: -188px -1669px; }
  &.glyphicon-bullhorn{ background-position: -236px -1669px; }
  &.glyphicon-dumbbell{ background-position: -284px -1669px; }
  &.glyphicon-suitcase{ background-position: -332px -1669px; }
  &.glyphicon-file_import{ background-position: -380px -1669px; }
  &.glyphicon-file_export{ background-position: -428px -1669px; }
  &.glyphicon-bug{ background-position: 4px -1717px; }
  &.glyphicon-crown{ background-position: -44px -1717px; }
  &.glyphicon-smoking{ background-position: -92px -1717px; }
  &.glyphicon-cloud-download{ background-position: -140px -1717px; }
  &.glyphicon-cloud-upload{ background-position: -188px -1717px; }
  &.glyphicon-restart{ background-position: -236px -1717px; }
  &.glyphicon-security_camera{ background-position: -284px -1717px; }
  &.glyphicon-expand{ background-position: -332px -1717px; }
  &.glyphicon-collapse{ background-position: -380px -1717px; }
  &.glyphicon-collapse_top{ background-position: -428px -1717px; }
  &.glyphicon-globe_af{ background-position: 4px -1765px; }
  &.glyphicon-global{ background-position: -44px -1765px; }
  &.glyphicon-spray{ background-position: -92px -1765px; }
  &.glyphicon-nails{ background-position: -140px -1765px; }
  &.glyphicon-claw_hammer{ background-position: -188px -1765px; }
  &.glyphicon-classic_hammer{ background-position: -236px -1765px; }
  &.glyphicon-hand_saw{ background-position: -284px -1765px; }
  &.glyphicon-riflescope{ background-position: -332px -1765px; }
  &.glyphicon-electrical_socket_eu{ background-position: -380px -1765px; }
  &.glyphicon-electrical_socket_us{ background-position: -428px -1765px; }
  &.glyphicon-message_forward{ background-position: 4px -1813px; }
  &.glyphicon-coat_hanger{ background-position: -44px -1813px; }
  &.glyphicon-dress{ background-position: -92px -1813px; }
  &.glyphicon-bathrobe{ background-position: -140px -1813px; }
  &.glyphicon-shirt{ background-position: -188px -1813px; }
  &.glyphicon-underwear{ background-position: -236px -1813px; }
  &.glyphicon-log_in{ background-position: -284px -1813px; }
  &.glyphicon-log_out{ background-position: -332px -1813px; }
  &.glyphicon-exit{ background-position: -380px -1813px; }
  &.glyphicon-new_window_alt{ background-position: -428px -1813px; }
  &.glyphicon-video_sd{ background-position: 4px -1861px; }
  &.glyphicon-video_hd{ background-position: -44px -1861px; }
  &.glyphicon-subtitles{ background-position: -92px -1861px; }
  &.glyphicon-sound_stereo{ background-position: -140px -1861px; }
  &.glyphicon-sound_dolby{ background-position: -188px -1861px; }
  &.glyphicon-sound_5_1{ background-position: -236px -1861px; }
  &.glyphicon-sound_6_1{ background-position: -284px -1861px; }
  &.glyphicon-sound_7_1{ background-position: -332px -1861px; }
  &.glyphicon-copyright_mark{ background-position: -380px -1861px; }
  &.glyphicon-registration_mark{ background-position: -428px -1861px; }
  &.glyphicon-radar{ background-position: 4px -1909px; }
  &.glyphicon-skateboard{ background-position: -44px -1909px; }
  &.glyphicon-golf_course{ background-position: -92px -1909px; }
  &.glyphicon-sorting{ background-position: -140px -1909px; }
  &.glyphicon-sort-by-alphabet{ background-position: -188px -1909px; }
  &.glyphicon-sort-by-alphabet-alt{ background-position: -236px -1909px; }
  &.glyphicon-sort-by-order{ background-position: -284px -1909px; }
  &.glyphicon-sort-by-order-alt{ background-position: -332px -1909px; }
  &.glyphicon-sort-by-attributes{ background-position: -380px -1909px; }
  &.glyphicon-sort-by-attributes-alt{ background-position: -428px -1909px; }
  &.glyphicon-compressed{ background-position: 4px -1957px; }
  &.glyphicon-package{ background-position: -44px -1957px; }
  &.glyphicon-cloud_plus{ background-position: -92px -1957px; }
  &.glyphicon-cloud_minus{ background-position: -140px -1957px; }
  &.glyphicon-disk_save{ background-position: -188px -1957px; }
  &.glyphicon-disk_open{ background-position: -236px -1957px; }
  &.glyphicon-disk_saved{ background-position: -284px -1957px; }
  &.glyphicon-disk_remove{ background-position: -332px -1957px; }
  &.glyphicon-disk_import{ background-position: -380px -1957px; }
  &.glyphicon-disk_export{ background-position: -428px -1957px; }
  &.glyphicon-tower{ background-position: 4px -2005px; }
  &.glyphicon-send{ background-position: -44px -2005px; }
  &.glyphicon-git_branch{ background-position: -92px -2005px; }
  &.glyphicon-git_create{ background-position: -140px -2005px; }
  &.glyphicon-git_private{ background-position: -188px -2005px; }
  &.glyphicon-git_delete{ background-position: -236px -2005px; }
  &.glyphicon-git_merge{ background-position: -284px -2005px; }
  &.glyphicon-git_pull_request{ background-position: -332px -2005px; }
  &.glyphicon-git_compare{ background-position: -380px -2005px; }
  &.glyphicon-git_commit{ background-position: -428px -2005px; }
  &.glyphicon-construction_cone{ background-position: 4px -2053px; }
  &.glyphicon-shoe_steps{ background-position: -44px -2053px; }
  &.glyphicon-plus{ background-position: -92px -2053px; }
  &.glyphicon-minus{ background-position: -140px -2053px; }
  &.glyphicon-redo{ background-position: -188px -2053px; }
  &.glyphicon-undo{ background-position: -236px -2053px; }
  &.glyphicon-golf{ background-position: -284px -2053px; }
  &.glyphicon-hockey{ background-position: -332px -2053px; }
  &.glyphicon-pipe{ background-position: -380px -2053px; }
  &.glyphicon-wrench{ background-position: -428px -2053px; }
  &.glyphicon-folder_closed{ background-position: 4px -2101px; }
  &.glyphicon-phone_alt{ background-position: -44px -2101px; }
  &.glyphicon-earphone{ background-position: -92px -2101px; }
  &.glyphicon-floppy_disk{ background-position: -140px -2101px; }
  &.glyphicon-floppy_saved{ background-position: -188px -2101px; }
  &.glyphicon-floppy_remove{ background-position: -236px -2101px; }
  &.glyphicon-floppy_save{ background-position: -284px -2101px; }
  &.glyphicon-floppy_open{ background-position: -332px -2101px; }
  &.glyphicon-translate{ background-position: -380px -2101px; }
  &.glyphicon-fax{ background-position: -428px -2101px; }
  &.glyphicon-factory{ background-position: 4px -2149px; }
  &.glyphicon-shop_window{ background-position: -44px -2149px; }
  &.glyphicon-shop{ background-position: -92px -2149px; }
  &.glyphicon-kiosk{ background-position: -140px -2149px; }
  &.glyphicon-kiosk_wheels{ background-position: -188px -2149px; }
  &.glyphicon-kiosk_light{ background-position: -236px -2149px; }
  &.glyphicon-kiosk_food{ background-position: -284px -2149px; }
  &.glyphicon-transfer{ background-position: -332px -2149px; }
  &.glyphicon-money{ background-position: -380px -2149px; }
  &.glyphicon-header{ background-position: -428px -2149px; }
  &.glyphicon-blacksmith{ background-position: 4px -2197px; }
  &.glyphicon-saw_blade{ background-position: -44px -2197px; }
  &.glyphicon-basketball{ background-position: -92px -2197px; }
  &.glyphicon-server{ background-position: -140px -2197px; }
  &.glyphicon-server_plus{ background-position: -188px -2197px; }
  &.glyphicon-server_minus{ background-position: -236px -2197px; }
  &.glyphicon-server_ban{ background-position: -284px -2197px; }
  &.glyphicon-server_flag{ background-position: -332px -2197px; }
  &.glyphicon-server_lock{ background-position: -380px -2197px; }
  &.glyphicon-server_new{ background-position: -428px -2197px; }

}
