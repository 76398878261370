@import '_variables.less';

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  padding-left: 15px;
  padding-right: 15px;
}


/*************************************/
/* Angular Material overrides */
/*************************************/

md-input-container > md-select {
  margin: 0 0 20px 0;
}

/*form input color override*/
md-input-container.md-default-theme:not(.md-input-invalid):not(.md-input-focused):not(.disabled) label,
md-input-container:not(.md-input-invalid):not(.md-input-focused):not(.disabled) label,
md-input-container:not(.disabled) label,
md-input-container:not(.disabled) label.md-placeholder,
md-select:not(.disabled) .md-select-value.md-select-placeholder,
md-select:not(.disabled) md-select-value.md-select-value,
md-select:not(.disabled) md-select-value.md-select-placeholder {
  color: rgba(0,0,0,0.5);
  border-bottom-color: rgba(0,0,0,0.7);
}

md-input-container.md-default-theme input.md-datepicker-input,
md-input-container:not(.disabled) .md-input:not([disabled]),
md-input-container.md-default-theme:not(.md-input-invalid):not(.md-input-focused):not([disabled]) .md-input:not([disabled]),
md-input-container:not(.md-input-invalid):not(.md-input-focused):not(.disabled) .md-input:not([disabled]) {
  border-bottom-color: rgba(0,0,0,0.7);
}

md-input-container.md-default-theme.md-input-invalid .md-input, md-input-container.md-input-invalid .md-input {
  border-color: rgb(221,44,0);
}

.md-button[disabled="disabled"].md-default-theme.md-primary {
  color: rgba(0,0,0,0.38) !important;
}

md-checkbox.md-default-theme:not([disabled]).md-primary.md-checked .md-icon:after,
md-checkbox:not([disabled]).md-primary.md-checked .md-icon:after {
  border-color: #fff !important;
}

md-input-container.md-default-theme .md-input[disabled],
md-input-container .md-input[disabled],
[disabled] md-input-container.md-default-theme .md-input,
[disabled] md-input-container .md-input {
  border-bottom-color: transparent !important;
  background-image: linear-gradient(to right, rgba(0,0,0,0.38) 0%, rgba(0,0,0,0.38) 33%, transparent 0%);
  background-image: -ms-linear-gradient(left, transparent 0%, rgba(0,0,0,0.38) 100%);
}

md-input-container.md-input-has-value > input,
md-input-container.md-input-has-value textarea,
md-select:not(.disabled) md-select-value.md-select-value:not(.md-select-placeholder),
input.ng-valid {
  color: rgba(0,0,0,1) !important;
}

md-chips.md-default-theme .md-chips, md-chips .md-chips {
  color: rgba(0,0,0,0.87);
  box-shadow: 0 1px rgba(0,0,0,0.7);
}

.md-autocomplete-suggestions-container.md-default-theme li .highlight,
.md-autocomplete-suggestions-container li .highlight {
  color: #00ADE2;
}

md-input-container.no-errors-spacer .md-errors-spacer,
md-autocomplete.no-errors-spacer .md-errors-spacer {
  display: none;
  min-height: 0;
}

/* faux-label class implies an input being used solely as a label */
md-input-container.faux-label.md-default-theme.md-input-has-value > .md-input[disabled] {
  background-image: none;
  color: rgba(0,0,0,0.87);
}



md-input-container.md-input-has-value input,
md-input-container.md-input-has-value md-select,
md-input-container.md-input-has-value textarea {
  background-color: rgba(0,0,0,0.1);
}

md-toast.md-bottom {
  bottom: 45px;
}

md-toast.md-right {
  right: 50%;
  transform: translateX(50%);
}

autocomplete-suggestions-container.md-default-theme li.selected, 
.md-autocomplete-suggestions-container li#selected_option.selected {
  background: #ccc;
}

//******************************************/
/* Placeholder if want to add more visible marker when input has a value */
/* md-input-container.md-input-has-value > label {
/* }
/*******************************************/

//Selected list item
md-list-item.selected {
  background-color: fade(@md-primary-blue, 30%);
}
md-list-item.current {
  background-color: fade(@md-primary-blue, 50%);
}

_:-ms-fullscreen, :root .md-open-menu-container { /* IE-11 only */
  /* This fixes an elusive bug in IE, see ENG-890 */
  opacity: 0.9999 !important;
}