#ctakes-app {

  @font-face {
    font-family: Oxygen;
    src: local(Oxygen),url(/ui/_shared/fonts/Oxygen-Regular.ttf) format('opentype');
  }

  .search-codes-input {
    width: 75%;
  }

  .alpha-normalized-header > *,
  .alpha-normalized-list .list-header > *,
  .alpha-normalized-list md-list-item .md-list-item-inner > * {
    padding: 0 8px;
  }

  .alpha-normalized-set {
    margin-bottom: 8px;
  }

  .alpha-normalized-header .toggle-all {
    padding: 0 8px;
  }

  .instruction-list {
    width: 590px;
    margin: auto;
    opacity: .4;
  }

  .alpha-normalized-header {
    color: white;
    background: rgb(155,155,155);
    padding: 0 16px;
  }

  .header-switch {
    .md-thumb {
      background: rgb(230,230,230);
    }
    &.md-checked .md-thumb {
      background: rgb(255,255,255);
    }
    .md-bar {
      background: rgb(190,190,190);
    }
    &.md-checked .md-bar {
      background: rgba(66,165,245,.8);
    }
  }

  .entry-switch[disabled] {
    .md-thumb {
      background: rgb(250,250,250);
    }
    &.md-checked .md-thumb {
      background: rgb(66,165,245);
    }
    .md-bar {
      background: rgb(158,158,158);
    }
    &.md-checked .md-bar {
      background: rgba(66,165,245,.5);
    }
  }

  .container {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .content {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 48px
  }

  md-virtual-repeat-container {
    min-width: 500px !important;
  }

  footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;;
    height: 50px;
  }

  fieldset {
    position: relative;
  }

  fieldset .fieldset-options {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tab-content {
    padding: 10px 0 0;
  }

  .btn.glyphicons {
    line-height: 1.4;
  }

  .btn-group-colorpicker > button.btn-lg {
    width: 46px;;
    height: 46px;
  }

  .btn-group-colorpicker .dropdown-menu {
    padding: 4px;
  }

  .btn-group-colorpicker .dropdown-menu .btn-color {
    width: 25px;;
    height: 25px;
    margin: 2px;
  }

  .btn-link[disabled] {
    color: #999;
  }

  .page-header {
    margin-top: 0;
  }

  .page-header h1 {
    margin-top: 5px;
  }

  .interaction-disabled {
    pointer-events: none;
  }

  div.alert-box {
    overflow: auto;;

    height: 60px;
  }

  /*.button-inactive {
    background-color: #B9B9B9;
    border-color: #B9B9B9;
  }*/
  .active {
    font-size: 40px;

    cursor: pointer;
  }
  .inactive {
    font-size: 40px;

    cursor: pointer;
  }
  .active {
    font-size: 40px;

    cursor: pointer;
  }
  .inactive {
    font-size: 40px;

    cursor: pointer;
  }
  i.active {
    color: #49d249;
  }
  i.inactive {
    color: #da5959;
  }
  .conceptHeader {
    padding: 5px;
    padding: 6px 12px;

    border: 1px solid transparent;
    border-radius: 4px;
    background-color: rgb(236, 236, 236);
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .no-focus:focus {
    outline: none;
    border: 0;
  }
  .even {
    background-color: #f7f7f7;
  }
  .space {
    margin-left: 2em;
  }
  .greyout {
    opacity: .4;
  }
  .accordion-toggle:hover {
    text-decoration: none;
  }

  .CodeSearch md-select {
    min-width: 150px;
    margin: 12px 0 0;
  }

  .CodeSearch md-input-container {
    margin-top: 26px;
  }

  .centerHeader {
    margin-bottom: 20px;

    text-align: center;
  }
  .column-header span {
    text-decoration: underline;
  }
  .column-header-ctakes span {
    font-weight: bold;

    margin-top: 5px;
    margin-bottom: 5px;

    background: #f7f7f7;
  }
  .panel-group .panel {
    overflow: visible;
  }
  .my-animation {
    opacity: 1;
  }

  .my-animation.ng-hide {
    opacity: 0;
  }

  .my-animation.ng-hide-add,
  .my-animation.ng-hide-remove {
    -webkit-transition: .5s linear all;
            transition: .5s linear all;
  }

}
