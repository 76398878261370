.dashboard-icon-option .glyphicon {
  width: 24px;
  height: 24px;
  margin: auto 16px auto 0;
}

.icon-list-filters {
  margin: 0;
  padding: 0 8px 8px;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}

.icon-list-filters md-input-container {
  margin: 0;
}

.icon-list-filters md-input-container md-select {
  margin-bottom: 0;
}

.md-select-menu-container.icon-set-select-container {
  z-index: 999;
}

md-menu-content.icon-select-menu-content {
  padding-bottom: 0;
  min-width: 400px;
}

md-menu-content .ps-alt-text {
  float: right;
  margin: 0 0 0 16px;
}

.icon-select-glyph {
  font-size: 20px;
  padding: 2px 0 0 2px;
}

.highlight {
  font-weight: bold;
}