@keyframes fadeIn {
  from { opacity: 0; }
  to {opacity: 1;}
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes sidenavExpand {
  from {
    width: 68px;
    max-width: 68px;
    min-width: 68px;
  }
  to {
    width: 295px;
    max-width: 295px;
    min-width: 295px;
  }
}

@keyframes sidenavCollapse {
  from {
    width: 295px;
    max-width: 295px;
    min-width: 295px;
  }
  to {
    width: 68px;
    max-width: 68px;
    min-width: 68px;
  }
}