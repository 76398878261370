/*
Styles
 */
@app-root: '../../';

@import 'material.less';
@import 'material-icons.less';
@import 'theme.less';
@import 'ui-grid.less';
@import 'animations.less';
@import 'codemirror.less';
@import 'ps-icons.less';
@import 'browser-specific.less';

@import '@{app-root}admin/less/styles.less';
@import '@{app-root}admin/sets/less/styles.less';
@import '@{app-root}admin/sharing/less/styles.less';
@import '@{app-root}admin/ctakes/less/styles.less';
@import '@{app-root}admin/uploader/less/styles.less';
@import '@{app-root}vhr/less/styles.less';
@import '@{app-root}mpi/less/styles.less';
@import '@{app-root}viewer/less/styles.less';
@import '@{app-root}account/less/styles.less';
@import '@{app-root}queries/less/styles.less';
@import '@{app-root}reports/less/styles.less';
@import '@{app-root}dashboard/less/styles.less';
@import '@{app-root}_tools/less/styles.less';
@import '@{app-root}_shared/directives/styles.less';
@import '@{app-root}_shared/components/styles.less';

@media screen {
  /* You can @import directly into this media query */

  @import 'md-data-table.less';

  .ms-clear {
    display: none;
  }
  
  html {
    height: initial;
    background: initial;
    background-color: initial;
  }

  body {
    margin: 0;
    height: 100vh;
  }

  body > div[ui-view] {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  a {
    cursor: pointer;
  }

  a.no-decoration, a.no-decoration:hover {
    text-decoration: none;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .full-width {
    width: 100%;
  }

  .md-icon-spacer {
    width: 24px;
    height: 24px;
  }

  .flex {
    display: flex;
  }

  .fade-in,
  .transparent.fade-in {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s linear;
  }

  .no-margin {
    margin: 0 !important;
  }

  .margin-left-8 {
    margin-left: 8px;
  }

  .margin-right-8 {
    margin-right: 8px;
  }

  .ui-padding {
    padding: 8px;
  }

  .no-padding {
    padding: 0 !important;
  }

  .padding-left-8 {
    padding-left: 8px;
  }

  .padding-right-8 {
    padding-right: 8px;
  }

  .word-break {
    word-break: break-all;
  }

  .text-warn {
    color: #f44336;
  }

  .not-visible {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }

  .vertical-scroll {
    overflow-y: auto;
  }

  .autocomplete-row {
    md-input-container {
      margin: 16px 0 0;
      md-select,
      input {
        margin: 0;
      }
    }
    md-chips-wrap {
      padding: 0 0 7px 4px;
    }
  }

  .spacer {
    &-inline {
      &-sm {
        width: 8px;
      }
      &-md {
        width: 16px;
      }
      &-lg {
        width: 24px;
      }
    }
    &-vertical {
      &-sm {
        height: 8px;
      }
      &-md {
        height: 16px;
      }
      &-lg {
        height: 24px;
      }
    }
  }

  .underline {
    text-decoration: underline;
  }

  #main-header, #main-footer {
    position: relative;
    min-height: 0;
    padding: 0;
    .md-toolbar-tools {
      height: 100%;
      max-height: 100%;
      min-height: 0;
      padding: 0 24px;
    }
  }

  #main-layout {
    overflow: hidden;
  }

  .browser-unsupported-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 8px;
    background: rgb(240,0,0);
    color: #fff;
    .close-button md-icon {
      color: #fff;
    }
  }

  /*************************************/
  /* Header */
  /*************************************/

  .header-brand {
    background-image: url(/brand/img/logo.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 75%;
    margin: 0 0 0 -8px;
    width: 75%;
  }

  #left-sidebar-layout {
    position: absolute;
    & > .sidenav {
      z-index: 2;
      md-sidenav {
        width: 295px;

        &, md-content {
          overflow-x: hidden;
        }

        &.expanding {
          animation: sidenavExpand .2s ease-in-out;
          md-list-item:not(.collapse-toggle) .md-button *:not(md-icon) {
            opacity: 1;
            transition: opacity .2s linear .2s;
          }
        }

        &.collapsing {
          animation: sidenavCollapse .2s ease-in-out;
          md-list-item:not(.collapse-toggle) .md-button *:not(md-icon) {
            opacity: 0;
            transition: opacity .2s linear .2s;
          }
        }

        md-list-item.collapse-toggle .md-list-item-inner {
          min-height: 63px;
        }

        md-list-item.collapse-toggle .md-list-item-inner md-icon {
          margin-left: 0;
        }

        md-list-item:not(.collapse-toggle) {
          padding: 0;
        }

        md-list-item md-icon {
          margin-top: 0;
          margin-bottom: 0;
        }

        md-list-item:not(.collapse-toggle) .md-button {
          display: block;
          width: 100%;
          height: 100%;
          text-align: left;
          padding: 0 16px;
          min-height: 48px;
          line-height: 48px;
          margin: 0;
        }

        &.collapsed { //collapsed to icons only
          width: 68px;

          md-list-item:not(.collapse-toggle) .md-button *:not(md-icon){
            opacity: 0;
          };
          md-list-item .md-button {
            min-width: 48px;
          }
          md-list-item md-icon {
            padding: 0;
          }
        }
        md-list {
          padding: 0;
        }
        md-list-item .md-button.active  {
          background-color: #42a5f5;
          color: #fff;
          md-icon {
            color: #fff;
          }
        }
        md-list-item .md-list-item-inner {
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
    }
    & > .layout-content {
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

  }

  @media screen and (max-width: 960px) {
    #left-sidebar-layout .sidenav md-sidenav {
      padding-top: 64px;
    }
  }

  #main-header {
    flex: none;
    height: 64px;
    background-color: #fff;
  }

  .material-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 3px solid @light-blue;
    background-color: #eee;
    z-index: 75;

    @media screen and (max-width: 920px) {
      .wrapper {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    @media screen and (max-width: 960px) {
      .wrapper {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding: 5px;
      }
    }
  }

  #main-footer {
    flex: none;
    height: 42px;
  }

  .material-footer {
    background-color: #eee;
    border-top: 3px solid @light-blue;
    height: 42px;
    min-height:42px;
    .copyright-text {
      font-size: @footer-copyright-font-size;
    }
    .md-toolbar-tools {
      color: @text;
    }
    a {
      color: @links;
    }
    .userLinks a {
      font-size: @footer-user-links-font-size;
      display: block;
      margin: 0 10px;
      cursor: pointer;
      color: @links-secondary;
    }
    @media screen and (max-width: 960px) {
      .wrapper {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        padding: 5px;
      }
      .userLinks a {
        font-size:@footer-user-links-font-size - 5;
      }
      .copyright-text{
        font-size: @footer-copyright-font-size - 2;
      }
    }
  }

/*** Main content ***/
  #main-content {
    flex: 1 1 auto;
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100vw;
    position: relative;
  }

/*** Main content data list ***/
  .selected-record-heading {
    padding: 0 8px 0 16px;
    background-color: rgba(0,0,0,0.54);
    color: #fff;
  }

  md-list.data-list {
    position: relative;
    padding: 0;

    md-list-item {
      border-bottom: 1px solid rgba(0,0,0,0.12);
      &:not(.data-list-header) {
        position: relative;
      }
    }

    md-list-item > * {
      z-index: 2;
    }

    md-list-item._md-button-wrap > div.md-button:first-child {
      padding: 0 16px;
    }

    md-list-item.data-list-header,
    md-list-item .md-list-item-inner,
    md-list-item .pending-delete-message {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      line-height: 48px;
    }

    md-list-item.data-list-header > *,
    md-list-item .md-list-item-inner > *,
    md-list-item > *:not(md-list-item-inner):not(md-radio-group),
    md-list-item md-radio-group > *,
    md-list-item .pending-delete-message > * {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 0;
      box-sizing: border-box;
      padding-right: 8px;
      &.md-icon-spacer {
        width: 24px;
      }
    }

    md-list-item md-radio-group > * md-radio-button {
      height: 20px;
    }
  
    md-list-item.data-list-subheader {
      background-color: #9e9e9e;
      color: #fff;
      .md-list-item-inner > * {
        display: block;
        width: 100%;
      }
    }

    md-list-item:not(.data-list-header) {
      z-index: 8;
    }
  
    md-list-item .md-secondary-container button.data-list-checkbox {
      margin-right: 10px;
    }

    md-list-item:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 48px;
      opacity: 0;
      top: 0;
      left: 0;
      z-index: 1;
      visibility: visible;
      background-color: rgba(255,217,0,0.5);
    }
    
    md-list-item.highlight:before {
      animation: fadeInOut 1s linear;
    }

    md-list-item.pending-delete {
      .pending-delete-message {
        background-color: rgba(244,67,54,0.5);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: #fff;
        text-transform: uppercase;
        z-index: 3;
        padding: 0 24px 0 12px;
        font-weight: bold;
        md-icon {
          color: #fff;
          fill: rgb(63, 57, 57);
        }
      }
      & > *:not(.pending-delete-message) {
        opacity: 0.3;
      }
    }

    md-list-item.disabled {
      background-color: rgba(0,0,0,0.12);
    }

    md-list-item.updated {
      padding-left: 12px;
      border-left: 4px solid #00ade2;
    }

    md-virtual-repeat-container { 
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .md-virtual-repeat-scroller {
      margin-top: 48px;
      box-sizing: border-box;
    }

  }
  md-list-item.data-list-header.no-sticky {
    position: relative;
    padding: 0 24px 0 16px;
  }
  
  md-list-item.data-list-header {
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0,0,0,0.12);
    padding: 0 16px;
    position: sticky;
    background-color: #fff;
    top: 0;
    left: 0;
    color: rgba(0,0,0,0.6);
    z-index: 9;
  }
/*** Virtual Scroll List ***/

  md-list[imat-virtual-list]:not(.imat-sized) {
    display: flex;
    flex-direction: column;
      flex: 0 1 392px;/* MATCH MAX-HEIGHT */
    max-height: 392px;/*8 items + 1px borders*/
    min-height: 98px; /*2 items + 1px borders*/
  }
  
  md-list[imat-virtual-list]:not(.imat-sized) md-list-item,
  md-list[imat-virtual-list]:not(.imat-sized) md-list-item.md-no-proxy {
    flex: none;
  }
  md-list[imat-virtual-list] {
    padding-bottom: 0;
    padding-top: 0;
  }
  md-list[imat-virtual-list] md-list-item {
    border: 0 solid transparent;
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  md-list[imat-virtual-list] md-list-item:last-of-type {
    border-bottom-color: transparent;
  }
  md-list[imat-virtual-list] md-list-item:first-child {
    border-bottom-color: rgba(0,0,0,0.12);
  }

  [virtual-repeat-sizer] md-tab-content {
    overflow: hidden;
  }
  .list-item-data {
    width: 0;
    overflow: hidden;
  }

  .app-root-element {
    height: 100%;
    box-sizing: border-box;
  }
  
  [key-value-table],
  .key-value-table {
    width: auto;
    min-width: 300px;
    margin: 8px 0;
    word-break: break-all;

    border-collapse: collapse;

    border: 1px solid rgba(0,0,0,.12);
    background-color: #f5f5f5;
    [row],
    .row {
      border: 1px solid rgba(0,0,0,.12);
      &:before {
        display: block;

        width: 0;
        height: 40px;

        content: '';
      }
      [key],
      .key {
        font-size: 80%;
        font-weight: bold;

        min-width: 100px;
        padding: 8px;

        text-align: right;

        background-color: #f5f5f5;
      }
      [value],
      .value {
        min-width: 200px;
        padding: 8px;

        background-color: #fff;
      }
    }
  }


  md-tabs.md-default-theme .md-tab, md-tabs .md-tab,
  md-nav-bar.md-default-theme .md-button._md-nav-button.md-unselected, 
  md-nav-bar .md-button._md-nav-button.md-unselected {
    color: rgba(0,0,0,0.75);
  }

  md-nav-bar.md-default-theme .md-button._md-nav-button.md-active {
    color: rgb(75,174,246);
  }

  md-nav-bar.md-default-theme md-nav-ink-bar, md-nav-bar md-nav-ink-bar {
    color: rgb(75,174,246);
    background: rgb(75,174,246);
  }

  md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: rgb(0, 173, 226);
    background: rgb(0, 173, 226);
  }

  //Input error messages
  md-input-container.md-default-theme.md-input-invalid .md-char-counter, md-input-container.md-input-invalid .md-char-counter, md-input-container.md-default-theme.md-input-invalid .md-input-message-animation, md-input-container.md-input-invalid .md-input-message-animation, md-input-container.md-default-theme.md-input-invalid label, md-input-container.md-input-invalid label {
    display: block;
  }

  .screen-header {
    margin: 24px 0;
    .screen-title {
      margin: 0;
    }
    .md-button {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  //Fixes padding in md-button when icon is preceeding string
  a.md-button > md-icon + span {
    padding-right: 5px;
  }

  .md-toolbar-tools .no-space {
    font-size: 0;
    letter-spacing: 0;
  }

  .overflow-ellipsis {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-visible {
    overflow: visible !important;
  }

  .text-center {
    text-align: center;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .cursor-pointer {
    cursor: pointer;
    outline: none;
  }

  .no-data-message {
    padding: 24px;
  }

  .no-wrap {
    white-space: nowrap;
  }

  .overflow-break-word {
    overflow-wrap: break-word;
    word-wrap: break-word; /* Microsoft support */
  }

  .padded-break-word {
    word-break: break-word;
    padding: 0 2px;
  }

  .dialog {
    &-lg {
      min-width: 980px;
    }
    &-md {
      min-width: 600px;
    }
    &-sm {
      min-width: 300px;
    }
  }

  .label {
    color: rgba(0, 0, 0, 0.51);
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .value {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 2px;
  }

  .label-value-row,
  .icon-value-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 8px;
    position: relative;
    & > *[label] {
      .label;
      flex: 1 1 100%;
      max-width: 120px;
      padding-right: 8px;
    }
    & > *[value] {
      .value;
      flex: 1 1 100%;
      max-width: 180px;
      max-height: 100px;
      overflow-y: auto;
      word-break: break-all;
      padding-right: 8px;
      line-height: 1;
    }
    & > .md-button {
      align-self: center;
      flex: none;
      margin: -12px 0;
      padding: 0;
      float: right;
    }
    & > *[value]:first-child,
    & > *[value]:not(.ng-hide) {
      max-width: 300px;
    }
    &.vertical {
      flex-wrap: wrap;
    }
    &.vertical > * {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 0;
    }
  }

  .icon-value-row-start {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    margin: 8px 0;
    & > *[value] {
      .value;
      flex: 1 1 85%;
      max-width: 85%;
      padding-right: 8px;
      text-align: justify;
      overflow-x: auto;
    }
  }

  .sidenav-warning {
    background-color: #f44336;
    color: #fff;
    cursor: default;
    font-weight: 400;
    margin-bottom: 14px;
    text-transform: uppercase;
  }

  .virtual-repeat-pager {
    border-top: 1px solid #ccc;

    .md-button.md-icon-button {
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    .pager-pages .md-button {
      min-width: 30px;
      font-size: 18px;

      .md-button.md-primary {
        border-bottom: 3px solid #42a5f5;
        margin-bottom: 3px;
      }
    }

    .pager-meta {
      color: rgba(0,0,0,0.5);
    }
  }

  .fadeIn-200ms {
    animation: fadeIn 0.2s linear;
  }

  .fade-50 {
    opacity: 0.3;
    transition: opacity .2s linear;
  }

  .transparent {
    opacity: 0;
    visibility: hidden;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  .md-sidenav-right {
    overflow: hidden;
    min-width: 300px;
    // padding-bottom: 53px;
    & > md-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 8px;
      z-index: -1;
      box-sizing: border-box;
      .sidenav-title {
        margin: 0 0 8px;
        h1, h2, h3, h4, h5, .md-title {
          margin: 10px 0;
          line-height: 28px;
        }
        .md-button md-icon {
          line-height: 32px;
        }
      }
    }
    & > .actions {
      background-color: #fff;
    }
    & md-switch,
    & md-switch:last-of-type {
      margin: 8px;
    }
    & md-divider {
      margin-bottom: 14px;
      margin-top: 14px;
    }
  }
  
  @media (max-width: 1280px){
    .label-value-row {
      flex-direction: column;
      *:first-child, *:last-child {
        flex: 1 1 100%;
      }
    }
  }
}
