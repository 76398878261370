#tools-app {
  /* General layout */
  .inner-content-wrapper {
    position: absolute;
  }
  .mini-sidenav {
    border-right: 1px solid #E2E2E2;
  }
  .md-table-toolbar {
    background-color: rgba(255,255,255,0.3);
    border-bottom: 1px solid #efefef;
    input,
    md-select,
    md-select-value,
    md-list-item,
    md-checkbox {
      font-size: 14px;
    }
    md-input-container>md-select {
      margin: 0;
    }
  }

  /* Platform Status Page */
  .status-toolbar {
    background-color: rgb(250,250,250);
    border-bottom: 1px solid rgb(239,239,239);
    input {
      font-size: 14px
    }
  }

  /* List */
  .list-virtual-container {
    height: 620px;
  }
  md-sidenav md-list {
    padding: 0px;
  }  
  md-list-item.active {
    background-color: #42a5f5;
    color: #fff
  }

  /* Grid */
  md-grid-tile md-icon {
    padding-bottom: 48px;
  }

  .CodeMirror {
    padding: 0 !important;
  }

  .example-block {
    background-color: #fff;
  }

  md-list.data-list md-list-item.data-list-header {
    align-items: center;
    justify-content: flex-start;
    padding-right: 34px; //scrollbar offset
  }

}