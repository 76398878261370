@font-face {
  font-family: 'ps-icons';
  src:  url('/ui/_shared/icons/ps.eot');
  src:  url('/ui/_shared/icons/ps.eot') format('embedded-opentype'),
    url('/ui/_shared/icons/ps.ttf') format('truetype'),
    url('/ui/_shared/icons/ps.woff') format('woff'),
    url('/ui/_shared/icons/ps.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

md-icon.ps-icons, [class^="ps-icons"], [class*=" ps-icons"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ps-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ps-icons.ps_sets:before {
  content: "\e900";
}
.ps-icons.ps_limbo_search:before {
  content: "\e911";
}
.ps-icons.ps_persona_search:before {
  content: "\e912";
}
.ps-icons.ps_double_arrow_left:before {
  content: "\e901";
}
.ps-icons.ps_double_arrow_right:before {
  content: "\e90e";
}
.ps-icons.ps_adt:before {
  content: "\e900";
}
.ps-icons.ps_advance_directive:before {
  content: "\1f5e2";
}
.ps-icons.ps_ambulance:before {
  content: "\e902";
}
.ps-icons.ps_beaker:before {
  content: "\e903";
}
.ps-icons.ps_doctor:before {
  content: "\e904";
}
.ps-icons.ps_patient_detail:before {
  content: "\e905";
}
.ps-icons.ps_patient_summary:before {
  content: "\e906";
}
.ps-icons.ps_pill_bottle:before {
  content: "\e907";
}
.ps-icons.ps_pill:before {
  content: "\e908";
}
.ps-icons.ps_prescription:before {
  content: "\e909";
}
.ps-icons.ps_rx:before {
  content: "\e90a";
}
.ps-icons.ps_transcription:before {
  content: "\e90b";
}
.ps-icons.ps_vitals:before {
  content: "\e90c";
}
.ps-icons.ps_x_ray:before {
  content: "\e90d";
}
