@keyframes expand {
  from {
    width: 68px;
    max-width: 68px;
    min-width: 68px;
  }
  to {
    width: 295px;
    max-width: 295px;
    min-width: 295px;
  }
}

@keyframes collapse {
  from {
    width: 295px;
    max-width: 295px;
    min-width: 295px;
  }
  to {
    width: 68px;
    max-width: 68px;
    min-width: 68px;
  }
}

#vhr-app {
  .capitalize {
    text-transform: capitalize;
  }

  abbr {
    text-decoration: none;
  }

  .use-icon {
    padding: 0 5px;
  }

  /*************************************/
  /* Angular */
  /*************************************/

  [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
  }

  /*************************************/
  /* Angular Material overrides */
  /*************************************/

  /*************************************/
  /* Sidenav */
  /*************************************/

  #sidenav .md-button {
    width: 100%;
    text-align: left;
    text-transform: none;
  }

  #sidenav .md-button.active {
    font-weight: bold;
    color: #000;
    background-color: #EEE;
  }

  md-sidenav:not(.collapsed),
  md-sidenav.md-locked-open:not(.collapsed) {
    width: 295px;
    min-width: 295px;
    max-width: 295px !important;
  }

  #sidenav md-list-item {
    position: relative;
  }

  #sidenav md-list-item .record-count {
    position: absolute;
    right: 16px;
    top: 12px;
  }

  #sidenav.collapsed {

  }

  .patient-lookup-link {
    margin-bottom: 20px;
  }

  /*************************************/
  /* Footer */
  /*************************************/

  footer > md-toolbar > nav.navbar > div.container {
    padding: 24px;
    font-size: 70%;
  }

  /*************************************/
  /* Page content areas (not datepicker) */
  /*************************************/

  .divider-bar {
    border-right: 1px solid #CCC;
    margin: 5px 10px;
  }

  .badge-container {
    position: relative;
  }

  .badge {
    position: absolute;
    top: 0px;
    background: rgb(244,67,54);
    color: white;
    border: 2px solid white;
    width: 28px;
    height: 28px;
    line-height: 24px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    z-index: 100;
    right: -5px;
    top: -5px;
    text-align: center;
  }

  .filter {
    margin: 0 5px 5px 0;
    display: inline-block;
    vertical-align: middle;
  }

  .filter-icon {
    margin: 8px 0;
  }

  .filter-icon.disabled {
    opacity: 0.5;
  }

  .filters-buttons {
    display:inline-flex;
    vertical-align: text-bottom;
    width: 232px;
  }

  .loading-data {
    position: absolute;
    top: 40px;
    font-size: 2em;
    line-height: 2em;
    transform: translate(-50%, 0%);
    left: 50%;
  }

  .page-content-loading {
    padding: 20px;
  }

  .page-content {
    height: 100%;
  }

  .grid-content,
  .content-padding {
    padding: 20px;
  }

  .content-padding {
    padding: 20px;
  }

  md-toolbar.md-accent > .md-headline {
    color: #FFF;
  }

  .page-title {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    padding: 0px 20px;
  }

  .md-headline > .material-icons {
    display: block;
    float: left;
    padding: 4px 5px 0 0;
  }

  /*************************************/
  /* md-Dialog Content */
  /*************************************/

  .dialog-actions {
    padding-left: 0;
  }

  /*************************************/
  /* md-Input-Container */
  /*************************************/

  md-input-container {
    min-height: 58px;
  }

  /*************************************/
  /* Common datepicker */
  /*************************************/

  .md-datepicker-input-container {
    /* May not be an issue in Material > 1.1.1 */
    border-bottom-color: transparent !important;
    border-bottom-width: 0 !important;
  }

  /*************************************/
  /* Common Patient */
  /*************************************/

  #patient-header span {
    margin-right: 5px;
    margin-left: 5px;
  }

  #patient-header > span:first-child {
    font-weight: bold;
  }

  #patient-header md-menu.subscriptions button {
    cursor: pointer;
    margin: 0;
    min-width: 48px;
  }

  #patient-header md-menu.subscriptions md-icon {
    color: #f3c554;
    padding: 0;
  }

  #patient-header md-menu.subscriptions md-icon:last-of-type {
    margin-left: -8px;
  }

  #patient-header button.vhr-widget i.material-icons {
    line-height: 35px;
    vertical-align: middle;
  }

  #patient-header button.vhr-widget span {
    font-weight: bold;
  }

  /* setup patient header md items to be color white */
  md-toolbar.md-accent > md-select > md-select-value
  {
    color: #FFF !important;
    border-bottom-color: #FFF !important;
  }

  .recent-records-dropdown > md-select-value > .md-select-icon::after {
    color: #FFF !important;
  }

  vhr-report-timestamp {
    display: block;
    text-align: center;
  }

  vhr-age-banner {
    color: rgba(0,0,0,0.67);
    display: none;
  }

  .record-count {
    display: block;
    text-decoration: none;
    color: #fff;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #42a5f5;
  }

  /*************************************/
  /* Patient Lookup */
  /*************************************/

  #patient-lookup form[name=patientLookup] {
    padding: 0 0 20px 0;
  }

  #patient-lookup .page-content {
    padding: 20px;
  }

  #patient-lookup .page-content md-checkbox {
    font-size: 90%;
    width: 200px;
  }

  #patient-lookup .page-content md-select {
    margin-top: 0;/* May not be an issue in Material > 1.1.4 */
    width: 200px;
  }

  #patient-lookup .submit-message {
    font-size: small;
  }

  #patient-lookup .submit-message ul {
    margin: 0;
  }

  /*************************************/
  /* Patient Details */
  /*************************************/

  #patient-details h4 {
    text-transform: capitalize;
  }

  #patient-details .section-basic {
    min-height: 125px;
  }

  #patient-details .section-basic > div {
    -moz-column-fill: balance;
    -moz-column-count: 2;
    column-count: 2;
  }

  #patient-details .section-basic label {
    color: #a6a6a6;
    text-transform: capitalize;
  }

  #patient-details .section-basic label:after {
    content: ':';
    margin-right: 8px;
  }

  #patient-details .section-advanced {
  }

  #patient-details .section-advanced .md-title {
    margin: 25px 0 5px 0;
  }

  #patient-details .section-advanced .md-title:first-child {
    margin-top: 10px;
  }

  /*************************************/
  /* Patient Summary */
  /*************************************/

  #patient-summary {
    padding: 20px;
  }

  #patient-summary .patient-summary .md-title {
    margin: 0 0 5px 0;
  }

  #patient-summary .hitListUIGrid {
    min-height: 180px;
    margin-bottom: 20px;
    width: 100%;
  }

  /*************************************/
  /* Link type pages */
  /*************************************/

  .link-button {
    display: block;
    float: left;
    width: 225px;
    height: 200px;
    padding: 0 20px 20px 0;
  }

  .link-button > a {
    width: 100%;
    height: 100%;
    white-space: normal;
  }

  /*************************************/
  /* HIE pages */
  /*************************************/

  .hie-actions {
    padding: 0 0 20px 0;
  }

  .hie-floating-button {
    position: fixed !important;
    bottom: 50px !important;
  }

  /*************************************/
  /* PDF */
  /*************************************/

  .pdf-toolbar {
    min-height: 42px;
    max-height: 42px;
    background-color: rgb(238,238,238) !important;
    color: rgba(0,0,0,0.87) !important;
  }

  .pdf-num-input {
    width: 55px;
    margin: 6px 0;
  }

  .pdf-count-text {
    line-height: 42px;
    padding: 0 15px 0 5px;
    font-weight: 400;
    letter-spacing: .01em;
    font-size: 16px;
  }

  .rotate0 {-webkit-transform: rotate(0deg); transform: rotate(0deg); }
  .rotate90 {-webkit-transform: rotate(90deg); transform: rotate(90deg); }
  .rotate180 {-webkit-transform: rotate(180deg); transform: rotate(180deg); }
  .rotate270 {-webkit-transform: rotate(270deg); transform: rotate(270deg); }
  .fixed { position: fixed; top: 0; left: calc(50% - 480px); z-index: 100; width: 100%; padding: 1em; background: rgba(238, 238, 238,.9); width: 960px; }

  /*************************************/
  /* Notifications */
  /*************************************/

  md-toast .md-toast-content {
    padding: 15px;
  }

  md-toast.notification-toast-success {
    .md-toast-content {
      background-color: #689F38;
    }
  }

  md-toast.notification-toast-warn {
    .md-toast-content {
      background-color: #FFC107;
      color: #333;
    }
  }

  md-toast.notification-toast-error {
    .md-toast-content {
      background-color: #E53935;
    }
  }

  /*************************************/
  /* Modal record views */
  /*************************************/

  .modal-info-container {
    padding-top: 15px;
  }

  .dosage-instruction {
    margin-right: 10px;
    margin-left: 1px;
  }

  .dosage-instruction-card {
    margin: 0;
  }

  .dosage-instruction-summary {
    padding: 15px 15px 0 15px;
  }

  .dosage-instruction-card-content {
    padding: 0 15px 15px 15px;
  }

  .card {
    box-shadow: none;
    padding: 0;
  }

  .card-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);;
    text-transform: uppercase;
    padding: 0;
    height: 24px;
    outline: none;
  }

  .card-phi-header {
    color:#E53935;
    padding-bottom: 5px;
  }

  .card-header-title {
    color: #666666;
  }

  .card-content {
    padding: 0;
    background-color: transparent;
  }

  .record-patient-name {
    font-size: 20px;
    font-weight: normal;
    color: #444;
    margin: 10px 0 10px 0;
  }

  .record-data-label {
    text-transform: uppercase;
    color: #888;
    font-size: 12px;
  }

  .record-modal-toolbar {
    min-height: 50px;
    height: 50px;
  }

  md-toolbar.md-accent>.md-button.md-headline {
      color: #FFF!important;
  }

  .record-title::first-letter {
    text-transform: uppercase;
  }

  .record-patient-header {
    margin: 0 0 20px 0;
  }

  .record-patient-header > div {
    display: inline;
    margin: 0 10pt 0 0;
  }

  .record-data-row {
    padding: 0 0 15px 0;
  }

  .record-data-row ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .record-data-row li {
    display: inline;
    margin-right: 12px;
  }

  .record-data-row .concept-notes {
    margin-top: 16px;
  }

  .footnote-link {
    cursor: pointer;
    text-decoration: underline;
  }

  .record-data-row vhr-footnotes ol {
    list-style-position: outside;
    margin-top: 0;
    /*padding-left: 26px;*/
    white-space: pre-wrap;
    font-family: monospace;
    font-size: 16px;
  }

  .record-data-row vhr-footnotes li {
    display: list-item;
    padding-bottom: 20px;
  }

  .general-notes {
    padding-bottom: 20px;
  }

  .transcription-notes{
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE 5.5+ */
  }

  .attachments-content {
    font-family: monospace;
    font-size: 16px;
    margin: 2px 0 20px 0;
    word-wrap: break-word; /* IE 5.5+ */
  }

  .document {
    padding-top: 10px;
  }

  .original-document {
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE 5.5+ */
    font-family: monospace;
    font-size: 16px;
  }

  .document .attachments-content,
  .document .original-document {
    background-color: white;
    border: 1px solid lightgray;
    font-size: 13px;
    opacity: 1;
    padding: 10px;
  }

  .document .attachments-content.ng-hide,
  .document .original-document.ng-hide {
    opacity: 0;
  }

  .document .attachments-content.ng-hide-add,
  .document .attachments-content.ng-hide-remove,
  .document .original-document.ng-hide-add,
  .document .original-document.ng-hide-remove {
    transition: opacity linear 0.5s;
  }

  .document vhr-pdf {
    display: block;
  }

  .document vhr-pdf.attachments-content {
    font-family: initial;
    padding: 0;
  }

  .lab-addresses {
    padding: 2px 0 20px 0;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE 5.5+ */
    font-family: monospace;
    font-size: 16px;
  }

  .section-name {
    display: block;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 120%;
    padding: 10px 0px;
  }

  md-dialog.record-modal {
    height: 98% !important;
    width: 98% !important;
  }

  md-dialog.record-modal > md-dialog-content,
  #grid-record-modal > md-dialog-content {
    margin: 0px;
    padding: 16px;
  }

  #grid-record-modal > md-dialog-content label,
  #cxml-record-modal > md-dialog-content label {
    font-weight: bold;
    text-transform: capitalize;
  }

  #grid-record-modal > md-dialog-content label:after,
  #cxml-record-modal > md-dialog-content label:after {
    content: ':';
    margin-right: 8px;
  }

  #grid-record-modal > md-dialog-content .record-data-row.concept label,
  #cxml-record-modal > md-dialog-content .record-data-row.concept label {
    line-height: 28px;/* ui-grid labels positioned on top */
  }

  #grid-record-modal > md-dialog-content .record-data-row.concept label:after,
  #cxml-record-modal > md-dialog-content .record-data-row.concept label:after {
    content: '';/* ui-grid labels positioned on top */
  }

  #grid-record-modal > md-dialog-content .record-data-row.document label,
  #cxml-record-modal > md-dialog-content .record-data-row.document label {
    line-height: 28px;/* ui-grid labels positioned on top */
  }

  #grid-record-modal > md-dialog-content .record-data-row.document label:after,
  #cxml-record-modal > md-dialog-content .record-data-row.document label:after {
    content: '';/* ui-grid labels positioned on top */
  }


  /*************************************/
  /* ui-grid */
  /*************************************/
  .grid {
    height: 550px;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
  }

  #patient-lookup .grid {
    margin-bottom: 20px;
  }

  .grid-small {
    height: 250px;
    //width: 98%;

  }

  .ui-grid-header-cell {
    background-color: #f3f3f3;
    color: rgba(0,0,0,0.87);
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    text-transform: uppercase;
  }

  /* selected grid row background */
  /* when row selected, bold text but keep row color the same */
  /*.ui-grid-row.ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    font-weight: bold;
  }
  */

  .ui-grid-row:nth-child(odd).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    background-color: #fdfdfd;
  }
  .ui-grid-row:nth-child(even).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    background-color: #f3f3f3;
  }
  .ui-grid-cell.underage {
    background-color: #FFC107 !important;
  }

  /* I want to select things from checkbox cell */
  .ui-grid-cell.ui-grid-disable-selection.ui-grid-row-header-cell {
    pointer-events: auto;
  }

  .ui-grid-icon-down-dir:before {
    content: '\E5C5';/*arrow_drop_down';*/
  }

  .ui-grid-icon-up-dir:before {
    content: '\E5C7';/*arrow_drop_up';*/
  }

  /* use material icon for view mode */

  .ui-grid-icon-ok:before {
    content: '\E8A0';/*pageview';*/
  }

  .selectable .ui-grid-cell { cursor:pointer; }

  /* make checkbox cell look similar to material checkboxes */
  .ui-grid-row-selected > div > div.ui-grid-row-header-cell {
    background-color: rgba(0,0,0,0.87) !important;
    color: #fff;
  }

  /*
  .ui-grid-row .ui-grid-cell.ui-grid-row-header-cell,
  .ui-grid-cell .ui-grid-cell.ui-grid-row-header-cell {
    background-color: #FFF;
  }
  */

  .ui-grid-row-selected.ui-grid-icon-ok:before {
    content: '\E5CA' !important;/*remove*/
  }

  .ui-grid-row-selected:hover.ui-grid-icon-ok:before {
    /*content: '\E15B' !important;*//*remove*/
  }

  .ui-grid-row-selected:hover > div > div.ui-grid-row-header-cell {
    background-color: rgba(0,0,0,0.87) !important;
  }

  .ui-grid-selection-row-header-buttons:hover {
    opacity: 1;
  }

  /* hover highlight color for grid rows */
  .ui-grid-row:hover .ui-grid-cell {
    /*background-color: #BBDEFB !important;*/
    background-color: #FFF9C4 !important;
  }

  /* avoid button outline when in focus */
  .ui-grid-icon-ok {
    outline: none;
  }

  /* attempted override but buggy
  .ui-grid .ui-grid-render-container-body .ui-grid-viewport {
    overflow: auto !important;
  }
  .ui-grid-viewport {
    overflow-x: auto !important;
  }*/

  .ui-grid-pager-control input {
    height: 13px;
  }

  .ui-grid-pager-control .ui-grid-pager-max-pages-number {
    vertical-align: middle;
  }

  .ui-grid-pager-panel {
    background: #efefef;
  }

  /* ui-grid misc. */

  .abnormal-indicator-column {
    color: firebrick;
  }

  .icon-left {
    float: left;
    padding: 5px 5px 0 0;
  }

  .icon-right-text {
    display: table-cell;
  }

  .icon-right {
    display: table-cell;
    vertical-align: middle;
  }

  /*************************************/
  /* Loading Overlay */
  /*************************************/

  .vhr-loading-overlay {
    background-color: rgba(100,100,100,0.2);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
  }

  /*************************************/
  /* MISC */
  /*************************************/

  .vhr-overflow-scroll {
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  .vhr-overflow-scroll .viewport {
    overflow-y: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .hamburger-menu {
    display: none;
  }

  @media screen and (max-width: 960px) and (min-width: 240px) {
    .hamburger-menu {
      display: flex;
      margin-right: 15px;
      min-width: 0;
    }
  }

  .nav-dropdown {
    min-height: 64px;
    max-height: 64px;
    md-button {
      line-height: 64px;
    }
    md-menu {
      line-height: 64px;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    md-menu-content {
      overflow-y: visible;
    }
  }

  .hint { /* text under form */
    color: rgba(0,0,0,0.70);
    font-size: 12px;
    position: absolute;
    top: 33px;
  }

  .date-field-hint {
    top: 36px;
  }

  .panel-header {
    line-height:40px;
    font-size:16px;
    background:#eee;
  }
  .select-all-checkbox {
    padding-left:30px;
  }

  md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
    background-color: rgba(0,0,0,0.87);
  }

  md-checkbox.md-default-theme.md-checked .md-icon:after, md-checkbox.md-checked .md-icon:after {
    border-color: #fff;
  }


  .truncate-500 {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 233px;
  }

  /*************************************/
  /* Printer section */
  /*************************************/

  .record-collection-table-icon {
    width: 55px;
  }

  .record-collection-table-data {
    width: 30%;
  }

  .print-version {
    display: none;
  }

  /*************************************/
  /* Sidebar */
  /*************************************/



  #vhr-layout {
    position: absolute;
    md-sidenav {
      width: 295px;

      &, md-content {
        overflow-x: hidden;
      }

      md-list {
        width: 100%;
      }

      md-list-item.collapse-toggle .md-list-item-inner {
        min-height: 63px;
      }

      md-list-item.collapse-toggle .md-list-item-inner md-icon {
        margin-left: 0;
      }

      md-list-item:not(.collapse-toggle) {
        padding: 0;
      }

      md-list-item:not(.collapse-toggle) .md-button span:not(.record-count) {
        transition: opacity .2s linear .2s;
      }

      md-list-item md-icon {
        margin-top: 0;
        margin-bottom: 0;
      }

      md-list-item:not(.collapse-toggle) .md-button {
        display: block;
        width: 100%;
        height: 100%;
        text-align: left;
        padding: 0 16px;
        min-height: 48px;
        line-height: 48px;
        margin: 0;
      }

      &.expanding {
        animation: expand .2s ease-in-out;
      }

      &.collapsing {
        animation: collapse .2s ease-in-out;
        md-list-item:not(.collapse-toggle) .md-button span:not(.record-count) {
          opacity: 0;
          transition: opacity .2s linear;
        }
      }

      &.collapsed { //collapsed to icons only
        width: 68px;
        max-width: 68px;
        min-width: 68px;

        md-list-item:not(.collapse-toggle) .md-button span:not(.record-count){
          opacity: 0;
        };
        md-list-item:not(.collapse-toggle) .md-button span.record-count {
          top: 4px;
          line-height: 16px;
          width: 16px;
          height: 16px;
          font-size: 10px;
        }
        md-list-item .md-button {
          min-width: 48px;
        }
        md-list-item md-icon {
          padding: 0;
        }
      }
      md-list {
        padding: 0;
      }
      md-list-item .md-button.active  {
        background-color: #42a5f5;
        color: #fff;
        md-icon {
          color: #fff;
        }
        span.record-count {
          color: #42a5f5;
          background-color: #fff;
        }
      }
      md-list-item .md-list-item-inner {
        white-space: nowrap;
        overflow-x: hidden;
      }
    }
    & > .layout-content {
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

  }

  @media (max-width: 960px) {
    #left-sidebar-layout .sidenav md-sidenav {
      padding-top: 64px;
    }
  }
}
