.material-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

material-loader[behavior="absolute"] {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

material-loader[behavior="block"] {
  position: relative;
  width: 100%;
  min-height: 2em;
}

material-loader[behavior="sticky"] {
  position: sticky;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 9;
}

material-loader[align="left"] {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

material-loader[align="right"] {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.linear {
  min-height: 5px;
  width: 100%;
}

.circular {
  position: relative;
}