md-input-container.ps-masked-input {
    position: relative;
    outline: none;

    .pseudo-input {
        width: 100%;
        max-height: 30px;
        border-bottom: 1px solid rgba(0,0,0,0.7);
    }

    .md-input {
        border: none;
        padding: 2px;
        order: 1;
        outline: none;
    }

    .md-button.md-icon-button {
        order: 2;
        margin: 0;
        height: auto;
        padding: 0;
        min-height: 30px;
    }

    &.md-input-has-value {
        .pseudo-input {
            background-color: rgba(0,0,0,0.1);
        }
        .md-input {
            background-color: transparent !important;
        }
    }

    &.md-input-invalid .pseudo-input {
        border-bottom: 1px solid #dd2c00;
    }

    &.md-input-focused .pseudo-input {
        border-bottom: 2px solid #42a5f5;
    }

    &.md-input-focused.md-input-invalid .pseudo-input {
        border-bottom: 2px solid #dd2c00;
        md-icon {
            color: #dd2c00;
            fill: #dd2c00;
        }
    }

    .transcluded.active {
        display: block;
        height: 20px;
        min-height: 20px;
    }

    [ng-messages] {
        [ng-message] {
            display: none;

            &.md-input-message-animation {
                display: block;
            }
        }
        
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    md-input-container.ps-masked-input {
      .md-input, .md-button.md-icon-button {
        display: inline-block;
      }
      .md-input {
        flex: 1;
        width: 90%;
      }
      .md-button.md-icon-button {
        width: 48px;
        position: relative;
        right: 0;
      }
    }
}