#queries-app {

  /*queries specific styles*/
  #queries {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .no-margin {
    margin: 0;
  }
  .word-wrap {
    word-wrap: break-word;
  }
  .height-100 {
    height: 100%;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }

  .tooltip-bold {
    font-weight: 700;
  }

  md-input-container {
    margin: 18px 0;
  }

  #queries .actions-bar {
    min-height: 52px;
    padding: 0 10px;
  }
  #queries .inner-content {
    padding: 24px 24px 0px;
  }
  #queries .inner-content > md-tabs {
    border: 1px solid #e2e2e2;
    margin-top: 16px;
  }
  .inner-content-banner {
    padding-bottom: 15px;
  }
  .query-builder-section {
    padding: 24px 16px;
  }
  .query-builder-footer-buttons {
    padding: 0 0 16px 0;
  }
  .query-builder-results-toolbar {
    background-color: #fff;
    border-top: 1px solid #ccc;
    color: #333;
    min-height: 54px;
  }
  .query-builder-results-toolbar-content {
    padding: 0 10px;
  }

  #queries md-input-container {
    margin: 8px 0;
  }

  #page-content {
    overflow-x: hidden;
  }

  .progress-container {
    transition: opacity 0.6s linear;
    width: 100%;
  }
  .progress-container-message {
    background-color: #F5F5F5;
    padding: 0 10px;
    font-size: 16px;
  }
  .progress-container-message > span {
    display: inline-block;
  }

  .md-sidenav-right {
    min-width: 400px;
    max-width: 600px;
    z-index: 1;
  }

  #queries md-list {
    padding: 0px;
  }
  
  .list-text {
    line-height: 16px;
    margin:12px 0; 
    /*white-space: pre-wrap; */
  }

  .document {
    font-family: monospace;
    font-size: 12px;
    white-space: pre-wrap;
  }
  csmkdiv {
    display: block;
  }
  .selected-list-item {
    color: white;
  }
  .selected-list-item h3 {
    color: white;
  }
  .selected-list-item p {
    color: white;
  }
  #queries .highlight,
  #queries .highlight csmkdiv {
    background-color: yellow;
  }
  md-autocomplete-wrap.md-whiteframe-z1 {
    box-shadow: 0 0 0 0 transparent;
  }

  md-autocomplete {
    min-width: 250px;
  }

  .md-tab { 
    min-width: 125px;
  }

  .md-table-pagination .label {
    color: #333;
    font-size: 100%;
    font-weight: normal;
  }

  .tabs-content-only md-tabs-wrapper {
    display: none !important;
  }

  #queries-sidenav,
  #record-details-sidenav {
    width: 325px;
    z-index: 75; /* set sidenav above header when under med size */
    transition: width 0.2s linear;
    
    &.collapsed {
      width: 0;
      transition: width 0.2s linear;
      .update-all,
      md-content {
        opacity: 0;
        transition: opacity 0.1s linear;
      }
    }
    & > md-content {
      overflow-x: hidden;
      opacity: 1;
      transition: opacity 0.5s linear;
      margin-bottom: 72px;
    }
    md-list {
      white-space: nowrap;
    }
    md-list .md-subheader {
      color: white; 
      background-color: rgb(97,97,97);
      /*background-color: #828282;*/
      font-weight: bold; 
      width: 100%;
      .md-subheader-inner {
        padding: 12px;
      }
      .md-button md-icon {
        color: #fff;
      }
    }
    md-progress-circular {
      margin: 16px auto;
    }
    .update-all {
      opacity: 1;
      transition: opacity 0.5s linear;
      position:absolute;
      bottom: 0;
      width:100%;
      border-top: 1px solid #ccc;
      white-space: nowrap;
      overflow: hidden;
      md-checkbox span {
        white-space: nowrap;
      }
    }
  }

  #queries-mini-sidenav, 
  #record-details-mini-sidenav {
    border-right: 1px solid #E2E2E2;
    border-left: 1px solid #E2E2E2;
    background-color: #f5f5f5;
    width: 24px;
    overflow: hidden;

    &.collapsed {
      width: 24px;
      .md-button.md-icon-button {
        opacity: 0;
        margin-left: -24px;
      }
      &:hover {
        .md-button.md-icon-button {
          opacity: 1;
          margin-left: 0px;
          transition: all 0.2s linear;
        }
      }
    }

    .md-button.md-icon-button {
      margin: 0;
      padding: 0;
      width: 24px;
    }
  }

  .actions-bar md-icon {
    line-height: 35px;
  }

  .tab-default {
    width: 100%;
    text-align: center;
    padding: 36px 16px;
  }
  .save-results-dialog {
    min-width: 600px;
  }

  .filter-row {
    margin: 0px 0 24px 0;
  }

  .set-desc-form {
    padding: 8px 8px 16px 8px;
  }

  .set-desc-autocomplete {
    padding: 8px 8px 8px 0;
  }

  /* override single line behavior for SETs*/
  .md-autocomplete-suggestions-container .autocomplete-2-lines li {
    line-height: inherit;
    height: auto;
    padding: 16px;
    white-space: normal;
  }

  .js_origDoc {
    overflow-y: auto !important;
  }
}
