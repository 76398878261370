#sets {

  .md-autocomplete-container {
    margin-top: 18px; 
    margin-bottom: 24px;

    md-input-container {
      margin: 0;
      flex-basis: auto;
      display: flex;
    }

    .ng-messages-container {
      height: 19px;

      .ng-messages {
        color: rgb(213, 0, 0);
        font-size: 12px;
        line-height: 14px;
        overflow-x: hidden;
        overflow-y: hidden;
        padding: 5px 5px 0 0;
      }
    }
  }


  form[name=setEditForm] md-autocomplete,
  form[name=setAddForm] md-autocomplete {
    background-color: transparent;
    height: 30px;
  }
  form[name=setEditForm] md-autocomplete-wrap,
  form[name=setAddForm] md-autocomplete-wrap {
    box-shadow: none;
    height: 30px;
    display: block;
  }

  // @media (max-width: 1280px) {

  //   form[name="setEditForm"] div[layout="column"],
  //   form[name="setEditForm"] div[layout="row"],
  //   form[name="setAddForm"] div[layout="column"],
  //   form[name="setAddForm"] div[layout="row"] {
  //     max-width: 100%;
  //     display: block;
  //     div[layout="row"] {
  //       width: 100%;
  //       display: block;
  //     }
  //     md-input-container {
  //       max-width: 100%;
  //       flex-wrap: wrap;
  //       display: flex;
  //       margin-bottom: 24px;
  //     }
  //   }
  // }

  div.CodeMirror {
    border: 1px solid rgba(0,0,0,.7);
    margin-top: 12px;
  }

  table.md-table {
    table-layout: fixed;
    thead.md-head {
      th.md-column {
        padding-right: 0 28px 0 0 !important;
        white-space: normal !important;
        width: 24%;
        min-width: 200px;
      }
      th:nth-child(2) {
        width: 12%;
      }
      th:nth-child(3) {
        width: 12%;
      }
      th:nth-child(4) {
        width: 15%;
      }
      th:nth-child(5) {
        width: 10%;
      }
      th:nth-child(6) {
        width: 12%;
      }
      th:last-child {
        width: 15%;
      }
    }

    tbody.md-body {
      td.md-cell:first-child {
        padding: 0 28px 0 24px !important;
      }
      td.md-cell {
        padding: 0 28px 0 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        word-wrap: break-word;
      }
    }
  }

  .input-validation {
    padding: 0 0 24px 0;
    color: #e51b24;
    font-weight: bold;
    border: none;
  }

  .input-validation-icon {
    color: #e51b24 !important;
  }

  .even {
    background: rgba(0, 0, 0, .05);
  }

  .outdated {
    font-weight: bold;
    color: rgba(255, 82, 82, 1);
  }

  md-toast {
    position: fixed;
  }

  &.md-toast-animating {
    overflow: auto !important;
  }

}
