////////////////////////////
// CodeMirror custom styles
////////////////////////////

// Background highlight color when clicking on word in CodeMirror instance.
.cm-matchhighlight {
  background-color: #ff0;
}

// Highlights position of highlight word on scrollbar.
.CodeMirror-selection-highlight-scrollbar {
  background-color: darken(#ff0, 10%);
}

// Admin > Data-Management's record view modal's CM.
.modalCM {
  .CodeMirror {
    height: 100%;
  }
  .CodeMirror-gutters {
    position: absolute;
    left: 0px !important;
  }
  .CodeMirror-gutter-wrapper {
    position: absolute;
    left: 0px !important;
  }
  .CodeMirror-linenumber {
    transform: translateX(-100%);
  }
}