/***************************/
/* UI-Grid Override Styles */
/***************************/

// Implemented to address Jira ticket UI-2962.
.ui-grid-header-viewport {
  height: 29px;
}

//Implemented to address Jira ticket UI-2777.
.ui-grid-cell-contents {
  font-size: 13px;
  line-height: 1.8em;
}

.ui-grid-header-cell div[role="columnheader"].sortable .ui-grid-icon-blank:before {
  content: '\e164';
  font-size: 16px !important;
  color: #777;
  visibility: visible;
  margin-top: -3px !important;
  margin-left: 5px !important;
}

/*** UI Grid Icon Overrides ***/
[class*=" ui-grid-icon"]:before, [class^=ui-grid-icon]:before {
  font-family: 'Material Icons';
  width: auto;
  font-size: 24px;
  vertical-align: middle;
  margin: 0;
}
.ui-grid-icon-ok:before {
  content: '\e876';
}
.ui-grid-menu-button .ui-grid-icon-menu:before {
  content: '\e5d2';
}
.ui-grid-menu-inner {
  .ui-grid-icon-cancel:before {
    content: '\e835';/*pageview';*/
  }
  .ui-grid-icon-ok:before {
    content: '\e834';/*pageview';*/
  }
}