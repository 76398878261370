ps-pager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(0,0,0,0.12);
  background-color: #fff;
  z-index: 9;
  padding-left: 8px;
  padding-right: 8px;
}

ps-pager .pager-pages .md-button {
  min-width: 48px;
}

ps-pager .limit-input md-select {
  margin: 0;
}