@theme: 'imat';
@img-base-path: '../img/';
@fontBasePath: "/ui/_shared/fonts/";

// Core variables and mixins
@import "../../_shared/styles/variables.less";
@import "../../_shared/styles/glyphicons.less";

#dashboard-app {

  @import 'themes/@{theme}/styles.less';

  @font-face {
    font-family: Oxygen;
    src: local(Oxygen), url('@{fontBasePath}Oxygen-Regular.ttf') format('opentype');
  }

  //===================================
  // COMMON (viewing/editing)
  //===================================

  #left-sidebar-layout .layout-content div[ui-view=item-content] {
    overflow-y: auto;
  }

  .md-menu-toolbar {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

  .md-toolbar-tools > span {
    font-size: 16px;
  }

  .dashboard md-list {
    padding-top: 0;
  }

  .dashboard > div:first-child {
    width: 350px;
  }

  button.dashboard-icon-option.md-button md-icon {
    color: #000;
  }

  //-----------------------------------

  span.beta {
    height: 100%;
    overflow: hidden;
    position: absolute; top: 0; left: 0;
    width: 110px;
  }

  span.beta > .ribbon {
    background-color: rgba(244,67,54,0.4);
    color: #fff;
    display: block;
    letter-spacing: 1px;
    line-height: 30px;
    padding: 0;
    position: absolute; top: 8px; left: -80px;
    text-align: center;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
    transform: rotate(-45deg);
    width: 200px;
  }

  .item-tile .item-image > * {
    font-size: 80px;
    height: 80px;
    line-height: 1;
    max-height: 80px;
    max-width: 80px;
  }

  .item-tile .item-image > .material-icons {
    font-size: 92px;
    height: 92px;
    max-height: 92px;
    max-width: 92px;
    width: 92px;
  }

  //===================================
  // VIEWING
  //===================================

  .dashboard-view {

    //-----------------------------------
    // z-index
    //-----------------------------------

    .item-link .item-image,
    .item-tile .item-image {
      z-index: 1;
    }
    .item-link span.beta,
    .item-tile span.beta {
      z-index: 3;
    }
    .item-link a,
    .item-tile a {
      z-index: 5;
    }
    .item-link .item-badge,
    .item-tile .item-badge {
      z-index: 7;
    }

    //-----------------------------------

    .item-link {
      border-top: 1px solid rgba(0,0,0,0.1);
      cursor: pointer;
      line-height: 48px;
    }

    .item-link.invisible,
    .item-tile.invisible {
      display: none;
    }

    .item-link a,
    .item-tile a {
      height: 100%;
      position: absolute; top: 0; left: 0;
      width: 100%;
    }

    .item-link:hover a,
    .item-tile:hover a {
      background-color: rgba(0,0,0,0.1);
    }

    .item-link .item-badge {
      margin-left: 8px;
      position: relative;
    }

    .item-link .item-badge > md-icon {
      cursor: help;
    }

    .item-link .item-image > * {
      font-size: 32px;
      line-height: 1;
      min-height: 32px;
      min-width: 32px;
      padding-left: 20px;
      padding-right: 20px;
      vertical-align: middle;
    }

    .item-link .item-image > img {
      max-height: 32px;
      max-width: 32px;
    }

    //-----------------------------------

    .item-tile {
      background-color: white;
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 12px;
      display: inline-block;
      height: 152px;
      line-height: 16px;
      margin: 8px;
      padding: 18px;
      position: relative;
      text-align: center;
      vertical-align: bottom;
      width: 152px;
      overflow: hidden;
      word-break: break-word;
    }

    .item-tile span.beta > .ribbon {
      position: absolute; top: 8px; left: -76px;
    }

    .item-tile label {
      text-align: center;
    }

    .item-tile .item-badge {
      position: absolute; top: 8px; right: 8px;
    }

    .item-tile .item-badge > md-icon {
      cursor: help;
      float: right;
    }
  }

  //===================================
  // EDITING
  //===================================

  .dashboard-edit {

    //-----------------------------------
    // opacity
    //-----------------------------------

    .item-link span.beta > .ribbon,
    .item-tile span.beta > .ribbon,
    .item-link .item-image > *,
    .item-tile .item-image > * {
      opacity: 0.55;
    }
    .item-link label,
    .item-tile label {
      opacity: 0.65;
    }

    .item-link.invisible span.beta > .ribbon,
    .item-tile.invisible span.beta > .ribbon,
    .item-link.invisible .item-image > *,
    .item-tile.invisible .item-image > * {
      opacity: 0.2;
    }
    .item-link.invisible label,
    .item-tile.invisible label {
      opacity: 0.3;
    }

    //-----------------------------------
    // z-index
    //-----------------------------------

    .item-link span.beta,
    .item-tile span.beta {
      z-index: 2;
    }
    .item-link .item-image,
    .item-tile .item-image {
      z-index: 1;
    }
    .item-link label,
    .item-tile label {
      z-index: 3;
    }
    .item-link .md-secondary-container {
      z-index: 4;
    }
    .item-link .item-options,
    .item-tile .item-options {
      z-index: 5;
    }

    //-----------------------------------

    .item-link {
      background-color: rgb(250,250,250);
      border-top: 1px solid rgba(0,0,0,0.1);
      line-height: 48px;
    }

    .item-link .item-handle {
      cursor: move;
    }

    .item-link span.beta {
      cursor: move;
    }

    .item-link .item-image > * {
      cursor: move;
      font-size: 24px;
      line-height: 1;
      min-height: 24px;
      min-width: 24px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: middle;
    }

    .item-link .item-image > img {
      max-height: 24px;
      max-width: 24px;
    }

    .item-link label {
      color: black;
      cursor: move;
      font-size: small;
      overflow-x: hidden;
      position: absolute;
      text-decoration: none;
      text-overflow: ellipsis;
      width: 60%;
    }

    .item-link.inherited label {
      width: 40%;
    }

    .item-options .md-icon-button {
      background-color: white;
    }
    .item-options .md-icon-button > md-icon {
      background-color: white;
      color: currentColor;
    }
    .item-options .md-icon-button:not([disabled]) {
      box-shadow: 1px 1px 1px 0px rgba(0,0,0,0.3);
    }
    .item-options .md-icon-button:not([disabled]):hover {
      box-shadow: 4px 4px 4px 0px rgba(0,0,0,0.3);
    }

    .item-tile {
      background-color: rgb(205,205,205);
      border: 1px solid rgba(0,0,0,0.1);
      border-radius: 12px;
      cursor: move;
      display: inline-block;
      height: 152px;
      line-height: 16px;
      margin: 8px;
      padding: 18px;
      position: relative;
      vertical-align: bottom;
      width: 152px;
      overflow: hidden;
      word-break: break-word;
    }

    .item-tile span.beta > .ribbon {
      position: absolute; top: 8px; left: -76px;
    }

    .item-tile .item-options {
      position: absolute; top: 6px; right: 0px;
    }

    .item-tile label {
      color: black;
      position: static;
      text-align: center;
      width: auto;
    }
  }

}
