.material-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
  border-bottom: 3px solid @light-blue;
  background-color: #eee;
  z-index: 75;

  @media screen and (max-width: 920px) {
    .wrapper {
      margin-left: 0px;
      margin-right: 0px;
      max-width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media screen and (max-width: 960px) {
    .wrapper {
      margin-left: 0px;
      margin-right: 0px;
      max-width: 100%;
      padding: 5px;
    }
  }
}
