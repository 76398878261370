#reports-app {

  #reports {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .page-content {
    height: 100%;
    overflow-y: auto
  }

  /* code mirror height override to fill in space */
  .CodeMirror {
    height: 100%;
  }

  .pane-divider {
    outline: none;
    cursor: pointer;
    background-color: #f6f6f6;
    color: #999;
    border-right: 1px solid #999;
    border-left: 1px solid #999;
    md-icon {
      color: #999;
      fill: #999;
      stroke: #999;
    }
  }

/*** Main content toolbar - filtering and actions  ***/
  // Makes input fields' placeholders/labels uniformly sized.
  //copied from admin.less
  .md-table-toolbar {
    background-color: rgba(255,255,255,0.3);
    border-bottom: 1px solid #efefef;
    md-input-container>md-select {
      margin: 0;
    };
    input,
    md-select,
    md-select-value,
    md-list-item,
    md-checkbox {
      font-size: 14px;
    }
  }

  //copied from admin.less
  .md-sidenav-right {
    overflow: hidden;
    min-width: 300px;
    & > md-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 8px 8px 60px;
      z-index: -1;
      box-sizing: border-box;
      .sidenav-title {
        margin: 0 0 8px;
        h1, h2, h3, h4, h5, .md-title {
          margin: 10px 0;
        }
      }
    }
    & > .actions {
      width: 100%;
      position: absolute;
      bottom: 0;
      border-top: 1px solid #ccc;
      background-color: #fff;
    }
    & md-switch,
    & md-switch:last-of-type {
      margin: 8px;
    }
    & md-divider {
      margin-bottom: 14px;
      margin-top: 14px;
    }
  }

  .current-access-list {
    min-height: 300px;
    md-list-item {
      min-height: 0px;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      &:before {
        min-height: 30px;
      }
      & > md-icon:first-child:not(.md-avatar-icon) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  md-list-item>md-icon:first-child:not(.md-avatar-icon)

  & {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }

  & > header,
  & > footer {
    position: relative;
    width: 100%;
    flex: none;
    height: 5vh;
    min-height: 64px;
  }

  & > header > md-toolbar.material-header {
    position: relative;
  }

  & > #content.container {
    flex: 1 1 auto;
    overflow-y: hidden;
    position: relative;
    height: 90vh;
  }

  & > div[ui-view].view-index {
    height: 100%;
  }

  #toolbar-layout {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  div[ui-view="toolbar"]{
    flex: none;
  }

  div[ui-view="toolbar-view"]{
    flex: 1 1 auto;
    overflow-y: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }

  .absolute-fill {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .no-overflow {
    overflow: hidden;
  }

  a.sr-only {
    display: none;
  }



  /*html.md-default-theme, html, content@app.md-default-theme, body {
    background: white !important;
  }*/
  md-toolbar {
    padding: 0 12px;
  }

  footer {
    bottom: 0;
  }

  footer md-toolbar {
    height: 100%;
    padding: 10px 0;
  }

  .page-content-loading {
    padding: 20px;
  }

  md-tabs.md-no-animation md-tab-content {
    transition: none;
  }


  /*************************************/
  /* ui-grid */
  /*************************************/

  .grid {
    height: 550px;
    width: 100%;
  }

  .grid-small {
    height: 250px;
    width: 100%;
  }

  .ui-grid-row:nth-child(odd).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    background-color: #fdfdfd;
  }
  .ui-grid-row:nth-child(even).ui-grid-row-selected > [ui-grid-row] > .ui-grid-cell {
    background-color: #f3f3f3;
  }

  /* I want to select things from checkbox cell */
  .ui-grid-cell.ui-grid-disable-selection.ui-grid-row-header-cell {
    pointer-events: auto;
  }

  .ui-grid-icon-down-dir:before {
    content: '\E5C5';/*arrow_drop_down';*/
    font-size: 16px !important;
  }

  .ui-grid-icon-up-dir:before {
    content: '\E5C7';/*arrow_drop_up';*/
    font-size: 16px !important;
  }

  /* use material icon for view mode */

  .ui-grid-icon-ok:before {
    content: '\E8A0';/*pageview';*/
  }
  [class^="ui-grid-icon"]:before, [class*=" ui-grid-icon"]:before {
    font-family: 'Material Icons';
    width: auto;
    margin: -2px;
    font-size: 24px;
  }

  .selectable .ui-grid-cell { cursor:pointer; }

  /* make checkbox cell look similar to material checkboxes */
  .ui-grid-row-selected > div > div.ui-grid-row-header-cell {
    background-color: rgba(0,0,0,0.87) !important;
    color: #fff;
  }

  .ui-grid-row-selected.ui-grid-icon-ok:before {
    content: '\E5CA' !important;/*remove*/
  }

  .ui-grid-row-selected:hover > div > div.ui-grid-row-header-cell {
    background-color: rgba(0,0,0,0.87) !important;
  }

  .ui-grid-selection-row-header-buttons:hover {
    opacity: 1;
  }

  /* hover highlight color for grid rows */
  .ui-grid-row:hover .ui-grid-cell {
    /*background-color: #BBDEFB !important;*/
    background-color: #FFF9C4 !important;
  }

  /* avoid button outline when in focus */
  .ui-grid-icon-ok {
    outline: none;
  }

  .ui-grid-pager-control input {
    height: 13px;
  }

  .ui-grid-pager-control .ui-grid-pager-max-pages-number {
    vertical-align: middle;
  }

  .ui-grid-pager-panel {
    background: #efefef;
  }

  /* ui-grid misc. */

  .icon-left {
    float: left;
    padding: 5px 5px 0 0;
  }

  .icon-right-text {
    display: table-cell;
  }

  .icon-right {
    display: table-cell;
    vertical-align: middle;
  }
}
