//Firefox
@-moz-document url-prefix() {
  .md-tooltip {
    &.md-origin-top,
    &.md-origin-right,
    &.md-origin-bottom,
    &.md-origin-left {
      &.md-show,
      &.md-hide {
        transition: none !important;
      }
    }
  }
}

/* IE10, IE11 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #left-sidebar-layout > .sidenav md-sidenav md-list-item:not(.collapse-toggle) .md-button {
    display: block;
    & > md-icon,
    & > span {
      display: inline-block;
    }
  }
  #left-sidebar-layout > .sidenav md-sidenav.collapsed md-list-item:not(.collapse-toggle) .md-button {
    display: block;
    & > span {
      display: none;
    }
  }
}

/* PS-856 fix for double clear X */
/* reveal should be for visibility eye */
input[type=text]::-ms-clear { display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal { display: none; width : 0; height: 0; }
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
input[type=password]::-ms-clear { display: none; width : 0; height: 0; }
input[type=password]::-ms-reveal { display: none; width : 0; height: 0; }

/* MS Edge specific styles go here */
@supports (-ms-ime-align: auto) {
  #mpi-app {
    .virtual-repeat-pager {
      display: block;
    }
    .virtual-repeat-pager .pager-meta {
      float: left;
      line-height: 48px;
    }
    .virtual-repeat-pager .pager-nav {
      float: right;
    }
    .virtual-repeat-pager .pager-pages {
      display: inline-block;
    }
    .ms-clear {
      clear: both;
      height: 0;
      width: 0;
    }
    ps-pager {
      position: fixed;
      bottom: 34px;
      right: 10px;
    }  
  }
}