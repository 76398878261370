@primaryBlue: rgb(66,165,245);
@primaryGray: rgb(119, 119, 119);

#admin-app {

  .admin-loader {
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  
  admin-dashboard-details {
    z-index: 9;
  }

  .authtokens-message {
    max-width: 510px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    md-icon {
      padding: 0 8px 0 0;
      margin: 0;
    }
  }

  .data-list .md-subheader .md-subheader-inner {
    padding: 8px 0;
  }

  .data-list .md-subheader .md-subheader-content {
    align-items: center;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px;
    margin: 0;
    font-weight: 400;
    text-align: left;
    border: medium none;
  }

  .data-list md-list-item .md-button {
    padding: 0 16px;
  }

  .search-results {
    padding: 0 8px;
  }

  div[ui-view="main-content"] {
    position: relative;
    width: 100%;
    overflow-x: hidden;
  }

  admin-log-search {
    width: 100%;
  }

  .relative {
    position: relative;
  }

  .md-sidenav-right {
    overflow: hidden;
    min-width: 300px;
    padding-bottom: 53px;
    & > md-content {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 8px;
      z-index: -1;
      box-sizing: border-box;
      .sidenav-title {
        margin: 0 0 8px;
        h1, h2, h3, h4, h5, .md-title {
          margin: 10px 0;
          line-height: 28px;
        }
        .md-button md-icon {
          line-height: 32px;
        }
      }
      &.sidenav-flex-container {
        display: flex; 
        flex-flow: column;
        .sidenav-flex-height-element {
          flex: 0 1 auto;
          margin: 0;
          .sidenav-title {
            margin: 0;
          }
        }
        .sidenav-fill-height-element {
          flex: 1 1 auto; 
          overflow: auto;
        }
      }
    }
    & > .actions {
      background-color: #fff;
    }
    & md-switch,
    & md-switch:last-of-type {
      margin: 8px;
    }
    & md-divider {
      margin-bottom: 14px;
      margin-top: 14px;
    }
  }

  .primary-gray {
    color: @primaryGray;
  }

  .primary-blue {
    color: @primaryBlue;
  }

  md-toolbar.layout-content-toolbar .md-toolbar-tools {
    background-color: rgb(97,97,97);
    color: rgba(255,255,255,0.87);
    md-icon {
      color: rgba(255,255,255,0.87);
      fill: rgba(255,255,255,0.87)
    }
  }

  // md-toolbar...
  .ctakes-toolbar md-input-container > md-select,
  .filter-toolbar md-input-container > md-select,
  .groups-toolbar md-input-container > md-select,
  .sets-toolbar md-input-container > md-select,
  .users-toolbar md-input-container > md-select {
    margin: 0;
  }

  .content-padding {
    padding: 20px;
  }

  .page-content {
    height: ~"calc(100% - 64px)"; //100% - height of toolbar
    overflow-y: auto;
  }

  .overflow-auto {
    overflow-y: auto;
  }

  .overflow-y-hidden {
    overflow-y: hidden;
  }

  .data-list-subheader {
    background-color: #9e9e9e;
    color: #fff;
  }

  .list-item-data {
    width: 0;
    overflow: hidden;
    padding-right: 24px;
  }

  .list-tooltip-icon {
    position: relative;
    z-index: 1;
  }

  md-list.link-list {
    padding: 0;
  }

  md-list-item.link-list-item .link-list-item-content {
    padding: 16px;
  }

  .link-list-item .link-title, .link-list-item .link-description {
    margin: 0;
    line-height: normal;
  }

  .link-list-item .link-title {
    font-weight: 400;
  }

  md-sidenav:not(.sidebar-nav) .actions {
    border-top: 1px solid #ccc;
    position: absolute;
    bottom: 0;
    width: 100%;
    button {
      margin: 8px;
    }
  }

  .sidenav-actions {/* UNUSED */
    border-top: 1px solid #ccc;
    position: absolute;
    width: 100%;
    bottom: 0;
    right: 0;
  }

  .form-actions {
    border-top: 1px solid #ccc;
    z-index: 1000;
    background: #FFF;
  }

  .group-select {/* UNUSED */
    max-height: 480px;
    overflow-y: auto;
  }

  .upload-button {/* UNUSED */
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  // Makes the md-table-toolbar directive's input fields' placeholders/labels uniformly sized.
  .md-table-toolbar {
    background-color: rgba(255,255,255,0.3);
    border-bottom: 1px solid #efefef;
    input,
    md-select,
    md-select-value,
    md-list-item,
    md-checkbox {
      font-size: 14px;
    }
  }

  .md-dialog-content .md-table-toolbar {
    border-bottom: none;
  }

  md-dialog#dialog {
    md-toolbar {
      p {
        padding-left: 16px;
      }
      md-icon {
        float: right;
        margin: 0;
        color: #ffffff;
      }
    }
    md-dialog-content:not(.save-confirm-dialog, .create-config-dialog) {
      padding: 16px;
      md-input-container {
        width: 100%;
        margin: 0;
      }
    }
  }

  md-dialog#dialog md-dialog-actions, md-dialog#dialog md-input-container {
    padding: 0;
  }

  md-dialog#dialog div.md-errors-spacer {
    display: none;
  }

  //-------------------------------------
  // Custom <md-data-table> style overrides.
  //-------------------------------------

  table.md-table td.md-cell:not(.md-checkbox-cell),
  table.md-table th.md-column {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table.md-table td.md-cell.md-checkbox-cell {
    text-overflow: clip;
  }

  md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar).md-default .md-button.md-raised,
  md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default .md-button.md-raised {
    color: rgba(255,255,255,0.87);
  }

  md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar) md-icon,
  md-toolbar.md-table-toolbar:not(.md-menu-toolbar) md-icon {
    color: rgba(0,0,0,0.54);
    fill: rgba(0,0,0,0.54);
  }

  //-------------------------------------
  // Custom styles.
  //-------------------------------------

  .label {
    color: rgba(0, 0, 0, 0.51);
    font-size: 12px !important;
    line-height: 16px;
    text-transform: uppercase;
  }

  .value {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 2px;
  }


  ///////////////////////////////////////
  // ACCESS TOKENS
  ///////////////////////////////////////

  .token-input {
    line-height: 40px;
    padding: 0 8px;
  }

  .tokenForm {
    padding: 0 24px;
  }

  .select-with-search-header {
    .select-header-searchbox {
      border: none;
      outline: none;
      height: 100%;
      width: 100%;
      padding: 0;
    }
    .select-search-header {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
      padding-left: 10.667px;
      height: 48px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      width: auto;
    }
  }


  ///////////////////////////////////////
  // COLLECTIONS
  ///////////////////////////////////////

  .collections-toolbar md-input-container > md-select {
    margin: 0;
  }

  form[name='collectionForm'] {
    div > div > div:first-child {
      height: 100%;
    }
    md-list {
      height: 100%;
      div:first-child {
        height: 100%;
      }
    }
  }


  ///////////////////////////////////////
  // CONFIGURATION
  ///////////////////////////////////////

  section#configuration {

    #codeEditor {
      position: relative;
      top: 0px;
      .CodeMirror {
        height: ~"calc(100% - 65px)";
      }
    }

    .configuration-list {
      border-right: 1px solid #ddd;
    }

    md-input-container {
      width: 20vw;
      min-width: 200px;
    }

    .file-text-area {/* UNUSED */
      padding: 0;
      z-index: 0;
    }

    .file-text-area-label {/* UNUSED */
      padding-bottom: 8px;
    }

    .file-text-area-message {
      position: relative;
      min-height: 48px;
      padding-left: 16px;
    }
  }

  .build-file-name {
    display: flex;
    align-self: start;
    align-items: center;
    margin: 12px 0px 0px 12px;
    p {
      font-size: 12px;
      padding-left: 3px;
      margin: 0;
    }
    input {
      border: none;
      border-radius: 4px;
      background: #ddd;
      padding: 5px;
      margin-left: 5px;
    }
  }

  .create-config-dialog {
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0px 0px 0px;
    }
    md-input-container {
      width: 95%;
      margin: 0;
    }
  }

  .save-confirm-dialog {
    text-align: center;
    margin: 16px;
  }

  ///////////////////////////////////////
  // DASHBOARDS MANAGEMENT
  ///////////////////////////////////////

  .flex-height-container {
    display: flex; 
    flex-flow: column;
    .flex-height-element {
      flex: 0 1 auto;
      margin: 0;
    }
    .flex-height-fill-element {
      flex: 1 1 auto; 
      overflow: auto;
    }
  }

  ///////////////////////////////////////
  // DATA MANAGEMENT
  ///////////////////////////////////////

  .admin-tab-container {
    height: 100%;
    margin-top: -49px;
    padding-top: 49px;
  }

  div#records md-input-container {
    margin: 21px 0 0 16px;
    label {
      line-height: 1.7;
    }
  }

  #data-mgmt-record-query {
    min-width: 300px;
  }


  ///////////////////////////////////////
  // GROUP MANAGEMENT
  ///////////////////////////////////////

  .manage-groups-virtual-container {/* also, collections?? */
    height: 250px;
  }


  ///////////////////////////////////////
  // REPORT CONTAINERS
  ///////////////////////////////////////

  .processing-dialog {
    min-height: 110px;
  }


  ///////////////////////////////////////
  // SYMBOLS
  ///////////////////////////////////////

  .symbols-value-list {
    min-height: 98px;
    min-width: 350px;
  }

  .symbols-value-list md-list-item button {
    margin-left: -16px;
  }

  .symbols-autocomplete {
    min-width: 350px;
  }

  .symbols-column {
    margin: 0 20px;
  }

  .symbols-column .CodeMirror.CodeMirror-wrap {
    height: 40vh;
    min-height: 300px;
    width: 100%;
    border: 1px solid #ccc;
  }

  ///////////////////////////////////////
  // USER MANAGEMENT
  ///////////////////////////////////////

  .password-section {
    position: relative;
    &.disabled {
      padding: 8px;
    }
    &.disabled > *:not(.disabled-message){
      opacity: 0.3;
    }
    &.disabled .disabled-message {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      background-color: rgba(255,255,255,0.75);
    }
  }

  ///////////////////////////////////////
  // General Dynamic Admin Layout Stuff
  ///////////////////////////////////////

  @media (max-width: 960px) {
    #left-sidebar-layout .sidenav md-sidenav {
      padding-top: 0px;
    }
    .page-content {
      height: ~"calc(100% - 48px)"; //100% - height of toolbar
    }
  }

  @media (max-width: 1280px) {
    .label-value-row {
      flex-direction: column;
      *:first-child, *:last-child {
        flex: 1 1 100%;
      }
    }
  }

  @media (min-width: 1920px) {
    admin-dashboard-details {
      overflow: initial !important; // Fixes clipping at higher resolutions
    }
  }

}
