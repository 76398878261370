#mpi-app {

  /* Fix flex box layout for IE */
  /* keeping as reference but will break all the other screens */
  //html,body {
  //    display: flex;
  //    flex-flow:column nowrap;
  //    height:100vh; /* fix IE11 */
  //    min-height:100%; /* fix IE11 */  
  //}
  
  .ms-clear {
    display: none;
  }

  .page-content {
    height: 100%;
  }

  .bg-white {
    background-color: #fff;
  }

  .hidden-caption {
    padding: 4px 16px;
    color: #0000008a;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    background-color: #ccc;
  }

  .mpid-list .mpid .list-item-data.facility-cell {
    display: flex;
  }

  .mpid-list .mpid .list-item-data.facility-cell md-icon {
    margin: 0;
  }

  .comparison-switch {
    margin: 8px 0;
  }

  .select-checkbox {
    margin-right: 8px;
  }

  /*styles for vertical angular-ui tabs*/
  ul.nav-tabs.nav-stacked {
    float: left;
    width: 250px;
    margin-right: 10px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  ul.nav-stacked + div.tab-content {
    margin-left: 270px;
  }

  /* Highlight a PI record as one added by a user */
  .user-added,
  .user-added td {
    color: #000 !important;
    background-color: #FFF9C4 !important;
  }

  /* CSS for indicating whether a data field is active or inactive */
  .inactive {
    text-decoration: line-through;
    color: #bbb;
  }

  /* Tags for indicating use, type, etc. */

  .tag {
    color: @brand-warning-highlight !important;
    margin: 0px 0 0px 8px;
    cursor: default;
  }

  .action-bar-footer {
    background-color: #efefef !important;
  }

  .form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
  }

  .screen-header {
    margin: 30px 0;
    h2, .screen-title {
      margin: 0;
    }
  }

  .screen-header-right {
    padding-right: 8px;
  }

  .screen-title {
    margin-top: 11px;
  }

  /*
   * search page
   */

  .heading-toggle {
    outline: none;
    cursor: pointer;
  }

  .mpi-search-form .md-datepicker-input {
    font-size: 100%;
    min-width: 165px;
  }

  /*
   * Personas page
   */

  tbody#preferred > tr > td:nth-child(2) {
    min-width: 300px;
  } 

  .contained {
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .preferred {
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
  }

  .persona-label {
    padding: 5px;
    padding-right: 12px;
    font-weight: bold;
    vertical-align: text-top;
    min-width: 120px;
  }

  .list-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    max-width: 1000px;
    strong {
      display: block;
      padding-right: 12px;
      min-width: 160px;
    }
    md-icon.material-icons {
      width: 16px;
      height: 16px;
      min-height: 16px;
      min-width: 16px;
      font-size: 16px;
    }
  }

  md-cell md-icon.material-icons {
    width: 16px;
    height: 16px;
    min-height: 16px;
    min-width: 16px;
    font-size: 16px;
  }

  .raw-output{
    overflow-x: auto;
    border: 1px solid #adadad;
  }

  md-list md-list-item.scroll-padding {
    padding-right: 15px;
  }

  .persona-label-title {
    padding-right: 12px;
    font-weight: bold;
  }

  md-list.persona-clinical-record-table md-virtual-repeat-container {
    height: 480px;
  }

  md-list.persona-clinical-record-table md-list-item > div {
    padding: 0 3px;
    font-size: 14px;
  }

  .md-checkbox-container > md-checkbox {
      margin: 16px 29px 16px 3px;
      width: 24px;
  }

  md-list-item.persona-table-header {
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-weight: 700;
    &.scroll-padding {
      padding-right: 31px;
    }
  }

  .facility-name, .facility-mrn {
    padding: 24px !important;
  }

  #pi-records-toggle {
    position: absolute;
    top: 8px;
    right: 23px;
    font-size: 1.5em;
  }

  .persona-detail-block {
    width: 80%;
  }

  .nav-button.md-button.md-accent.md-raised md-icon {
    color: #fff; 
  }

  .sort-icon {
    display: inline-block;
    position: relative;
    height: 24px;
    width: 24px;
    line-height: 46px;
  }

  .sort-icon md-icon {
    position: absolute;
    left: 0;
    line-height: 40px;
  }

  .status {
    margin: 5px;
  }

  .pi-record-row md-switch,
  .facility-row md-switch {
    margin: 0;
  }

  .pi-record-row td.toggle {
    vertical-align: middle;
  }

  .loading {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    min-height: 200px;
  }

  [flex-cell] [flex-cell] {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 768px){
    .facilities {
      max-width: 100%;
    }
  }

  /* 
   * Linked Person Info Records & Clinical Records 
   */

  .pid-column {
    padding: 0 0 0 10px !important;
  }

  .user-added-icon {
    padding: 0 15px 0 0 !important;
  }

  .showDisabledPiRecords {
    position: relative;
    left: 24px;
    margin-top: 20px;
  }

  md-toolbar .md-menu {
    height: auto;
    margin: 0;
    padding: 0;
  }

  // md-toolbar.md-default-theme:not(.md-menu-toolbar) md-icon, md-toolbar:not(.md-menu-toolbar) md-icon {
  //   color: rgba(0,0,0,0.54);
  //   fill: rgba(0,0,0,0.54);
  // }

  md-toolbar.md-table-toolbar {
    box-shadow: none;
    display: flex;
    justify-content: center;
  }

  md-toolbar.md-table-toolbar.toolbar-placeholder {
    display: none;
  }

  /* Setup a transparent class */
  .toolbar-placeholder {
    background-color: transparent !important;
    color: rgba(0,0,0,0.87) !important;
  }

  md-checkbox.md-default-theme.md-checked .md-icon:after { 
    border-color: #fff; 
  }

  .list-close {
    position: absolute;
    right: 0px;
    animation: none;
  }

  .list-button {
    float: right;
    padding-right: 47px;
  }

  .list-icon {
    color: #777;
  }
  
  .list-container {
    overflow: hidden;
    padding-bottom: 15px;
  }

  .list-content {
    display: flex;
  }

  .list-header {
    font-weight: bold;
    text-align: left;
    padding: 0px 7px 7px 25px;
  }

  .list-item {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    min-height: 30px;
    height: 30px;
  }

  .list-checkbox {
    display: flex;
    align-items: center;
    margin: 0 auto;
    min-height: 30px;
    height: 30px;
  }

  .clinical-list-container {
    max-height: initial;
    overflow-y: hidden;
    padding-bottom: 15px;
  }

  .clinical-list-content {
    display: flex;
    overflow-y: hidden;
  }

  /* 
   * Linked Person Info Records
   */
   
  .linked-info-pid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px;
  }

  .column-name {
    display: flex;
    flex-direction: column-reverse;
  }

  .data-container {
    clear: both;
  }

  .mpid {
    position: relative;
    padding: 8px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    z-index: 1;
  }

  .mpid.dndDragging {
    background: #fff;
  }

  .mpid.collapsed .pi-record-list .pi-record {
    display: none;
  }

  .ui-draggable-dragging {
    background-color: #eee;
    box-shadow: 0 5px 5px 0px rgba(0,0,0,0.5);
  }

  .mpi-persona-list .data-list-header {
    font-size: 14px;
    padding: 0 12px 0 16px;
    z-index: 9; 
  }

  .mpi-persona-list .md-subheader,
  .piEditor-list .md-subheader {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }

  .mpi-persona-list .md-subheader .md-subheader-inner,
  .piEditor-list .md-subheader .md-subheader-inner {
    padding: 0;
  }

  .mpi-persona-list .md-subheader .md-subheader-content,
  .piEditor-list .md-subheader .md-subheader-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px;
    margin: 0;
    font-weight: 400;
    text-align: left;
    border: medium none;
  }

  .mpi-persona-list .md-subheader .md-subheader-content > div {
    width: 0;
    line-height: 40px;
  }

  .mpi-form-fields md-input-container {
    margin: 0 0 18px;
  }

  .mpi-form-fields md-input-container > md-select {
    margin: 0 0 24px;
  }
  
  .dndPlaceholder {
    display: none;
  }

  .dndDragging {
    border: 1px solid #333;
  }

  .anchor-list {
    padding: 0 14px;
  }

  .dndDraggingSource {
    opacity: 0;
    border: none;
  }

  .piEditor-list {
    display: block;
  }
  .mpid-list {
    width: 100%;
    overflow-y: auto; 
  }
  .column-headers {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
    max-height: 100%;
  }
  .column-headers .md-subheader-inner {
    padding: 0;
  }
  .column-headers .md-subheader-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 24px;
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .mpid-details {
    position: sticky; 
    top: 40px; 
    background: #fff;
    padding: 8px 16px;
    z-index: 9;
  }
  .mpid .list-item-data,
  .pi-record .list-item-data {
    display: block;
    padding-right: 8px;
    box-sizing: border-box;
    word-break: break-word;
    font-size: 12px;
  }
  .mpid-header,
  .mpid-details,
  .pi-record-list-header,
  .pi-record {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    line-height: 24px;
    flex-wrap: wrap;
  }

  md-icon[ng-click] {
    cursor: pointer;
    outline: none;
  }

  .facility-row {
    width: 100%;
    min-width: 100%;
  }

  .mpid-toolbar {
    padding: 0 0 0 28px;
  }

  .pi-record {
    position: relative;
    padding: 4px 16px;
    background-color: #eaeaea;
  }

  .pi-record:hover {
    background-color: rgba(0,173,226,0.3);
  }

  .pi-record:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0,173,226,0.5);
    opacity: 0;
    z-index: 1;
    transition: opacity 0.2s linear;
  }
  
  .mpid.altered .mpid-details,
  .pi-record.moved {
    padding: 4px 16px 4px 12px;
    border-left: 4px solid #00ade2;
    transition: all .2s linear;
  }

  .pi-record.highlighted:before {
    opacity: 1;
  }

  .pi-record > * {
    z-index: 2;
  }

  .mpid-header > div,
  .pi-record-list-header > div {
    width: 0;
  }
  
  .action-icons {
    width: 100px;
    line-height: 24px;
    & > *:not(div) {
      cursor: pointer;
    }
    & > md-menu .md-button.md-icon-button {
      padding: 0;
      height: 24px;
      min-height: 24px;
      width: 24px;
    }
    .not-visible {
      opacity: 0;
    }
  }
  
  .md-sidenav-right sup {
    padding: 0;
    vertical-align: text-bottom;
  }
  .details-warning-banner,
  .pending-delete-banner {
    background-color: #f44336;
    color: #fff;
    cursor: default;
    font-weight: 400;
    margin-bottom: 14px;
    text-transform: uppercase;
  }
  .details-warning-footnote {
    color: #f44336;
  }
  .list-mpids {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .list-mpids>li {
    margin-bottom: 15px;
  }
  .list-pi-entries {
    margin: 10px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0;
    list-style: none;
  }
  .list-pi-entries>li.highlight {
    color: #fff;
    border-color: #0099c9;
    background-color: #00ade2;
  }
  .pi-entry {
    position: relative;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .pi-entry:before {
    content: '';
    background-color: #FFE961;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .5s linear;
  }
  .pi-entry.dropped:before {
    background-color: #FFE961;
    opacity: 1;
    transition: opacity .5s linear;
  }
  .delete-icon,
  .expand-icon,
  .mpid-entry md-icon,
  .pi-entry .expand-icon {
    cursor: pointer;
  }
  .pi-entry {
    background: #fff;
  }
  .pi-entry .pi-content {
    display: block;
    position: relative;
    z-index: 1;
  }
  .pi-entry > div[layout="column"] > div[layout="row"] {
    padding: 5px 10px;
  }
  .mpid-entry-details {
    padding: 10px;
  }
  .pi-entry-details {
    padding: 0;
  }
  .pi-entry:nth-child(even){
    background-color: #eaeaea;
  }
  .pi-entry.ui-draggable-dragging {
    border: 1px solid #ccc;
    background-color: #FFE961 !important;
  }
  .list-columns {
    padding: 18px 10px 0;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .mpid-values {
    padding: 10px 2px 0px 10px;
  }

  .list-mpids>li, .list-pi-entries>li {
    line-height: 16px;
  }
  .list-mpids .glyphicons, .list-pi-entries .glyphicons {
    vertical-align: bottom;
    line-height: 15px;
  }

  .pi-entry.inactive span,
  .pi-entry.inactive div {
    text-decoration: line-through;
  }

  md-toast {
    position: fixed;
  }

  #load-mpid input {
    padding: 5px;
    border: 1px solid #ccc;
    width: 300px;
  }

  .rows {
    width: 100%;
  }
  .mpids-wrapper {
    padding: 24px 24px 64px;
    position: relative;
  }

  md-input-container .hint {
    color: rgba(0,0,0,0.70);
    font-size: 12px;
  }

  .mpid-entry-details [flex-cell],
  .pi-entry-details [flex-cell]{
     padding: 5px 0;
  }

  /* 
   * PI Link Editor
   */

  // Style override for back button on PI Link Editor page.
  // Positions button to be inline with screen title.

  #scroll-to-top {
    position: fixed;
    bottom: 120px;
    right: -16px;
    transition: all 0.2s linear;
    opacity: 0;
  }

  #scroll-to-top.active {
    right: 16px;
    opacity: 1;
  }

  .pi-link-editor-back-button {
    position: relative;
    top: -7px;
  }

  .mpid-options {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  .pid-options {
    display: flex;
    align-items: center;
  }

  .pi-link-editor-header {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  .pi-link-editor-list-columns {
    padding: 18px 10px 5px;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .action-bar-footer {
    position: sticky;
    bottom: 0;
    border-top: 1px solid #ccc;
  }

  .action-bar-footer .md-toolbar-tools > div:not(:last-child){
    margin-right: 8px;
  }

  .dialog-input-container {
    width: 100%;
  }

  /*
   * MPI Persona Hit list ui grid view
   */

   /* Workaround for IE11 so that menu items with md-checkbox will work */
  .columns-menu-content {
    md-menu-item {
      height: auto;
      min-height: auto;
      & > .menu-item-inner {
        padding: 0;
        margin: 0;
        md-checkbox {
          width: 100%;
          padding: 8px 16px;
          margin-bottom: 0;
          .md-container {
            left: auto;
          }
        }
      }
      &:hover {
        background-color: rgba(0,0,0,0.12);
      }
    }  
  }

    /*override of header height*/
    .ui-grid-header-canvas {
      height: 27px !important;
    }

    /*override of menu button default styling*/
  // Mozilla
    @-moz-document url-prefix() {
      .ui-grid-menu-button {
        height: 26px;
      }
    }
    // Chrome
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      .ui-grid-menu-button {
        height: 26px;
      }
    }
    // IE 10 & 11
    @media all and (-ms-high-contrast:none) {
      .ui-grid-menu-button {
        height: 26px;
      }
      *::-ms-backdrop, .ui-grid-menu-button {
        height: 26px;
      }
    }
    // Edge
    @supports (-ms-ime-align:auto) {
      .ui-grid-menu-button {
        height: 26px;
      }
    }

    /*override the behavior of not being able to select text*/
    .ui-grid-selectable .ui-grid-disable-selection {
      -webkit-touch-callout: default;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      cursor:auto;
    }


  /* 6/6/17 I think we should just live with the default behavior- Lara */  
  /* positions ascending/descending arrow indicator next to dropdown chevron */
  //  .ui-grid-icon-down-dir, .ui-grid-icon-up-dir {
  //    float: right;
  //    padding-right: 10px;
  //    position: relative;
  //    bottom: 2px;
  //    left: 6px;
  //  }

  /*positions sorting priority number next to sorting direction arrow indicator*/
  //  .ui-grid-sort-priority-number {
  //    float: right;
  //    position: relative;
  //    bottom: 1px;
  //    left: 8px;
  //}

  .ui-grid-column-menu-button .ui-grid-icon-angle-down {
    position: relative;
    left: 7px;
  }
  
  & {
    top: inherit !important;
  }

  /* PI Create */

  .identifier-block {
    max-width: 750px; // Prevent size change when hint displayed
  }

  md-icon.add-color {
    color: @brand-success;
  }

  .form-field-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
  }
  md-menu md-checkbox {
    padding: 8px;
  }
  .search-view {
    overflow-y:auto;
  }
  .select-container {
    margin-bottom: 42px;
  }
  md-input-container.select-container > md-select {
    margin-bottom: 0;
  }
  .page-content,
  [ui-view="main-content"] {
    max-width: 100%;  
  }

  .confirm-delete-list .scroll-container {
    height: 300px;
    overflow-y: auto;
  }
  .mpi-search-form {
    max-width: 716px;
  }
  .mpi-search-form md-input-container:not([flex]) {
    flex: 1 1 auto;
  }
  .more-hr {
    position: relative;
    text-align: center;
    outline: none;
    cursor: pointer;
  }
  .more-hr .md-caption {
    display: block;
    opacity: 0;
    padding: 4px 0;
  }
  .more-hr md-icon {
    position: relative;
    top: 0;
    transition: all 0.1s linear;
  }
  .more-hr:hover md-icon {
    top: 5px;
  }
  .more-hr.less:hover md-icon {
    top: -29px;
  }
  .more-hr:hover .md-caption {
    opacity: 1;
  }
  .more-hr.less md-icon {
    position: relative;
    top: -24px;
  }
  .more-hr.less .md-caption {
    position: relative;
    top: 24px;
  }
  md-input-container .hint {
    opacity: 0;
    position: absolute;
    left: 2px;
    right: auto;
    top: 35px;
    font-size: 12px;
    line-height: 14px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  }
  md-input-container.md-input-focused .hint {
    opacity: 1;
  }
  md-input-container.select-container .hint {
    bottom: -18px;
  }

  .search-view .list-virtual-container {
    min-height: 500px;
  }

  .virtual-repeat-pager {
    position: sticky; 
    bottom: 0px; 
    background: #fff;
    z-index: 9;
  }

  .search-results {
    position: relative;
  }
  
  md-list.data-list md-list-item {
    min-height: 24px;
    &:before {
      min-height: 24px;
    }
    .md-button,
    .md-list-item-inner {
      font-size: 12px;
      max-height: 24px;
      height: 24px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .md-button {
      padding-top: 4px;
      padding-bottom: 4px;
    }
    .md-button.data-list-checkbox {
      max-height: 42px;
      height: 42px;
      width: 42px;
    }
    .md-button.data-list-checkbox md-icon {
      line-height: 32px;
    }
  }

  .current-search-params {
    box-sizing: border-box;
    padding-left: 16px;
  }

  .current-search-params .param-list {
    border-top: 1px solid rgba(0,0,0,0.25);
    border-left: 1px solid rgba(0,0,0,0.25);
    border-right: 1px solid rgba(0,0,0,0.25);
    max-width: 90%;
  }

  .current-search-params ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .current-search-params li {
    border-bottom: 1px solid rgba(0,0,0,0.25);
    box-sizing: border-box;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  .current-search-params li .label {
    background-color: rgba(0,0,0,0.1);
    padding: 4px 8px;
  }

  .current-search-params li .value {
    padding: 4px 8px;
  }

/* The following are identical to the Personas page.

  .list-close
  .list-button
  .list-icon
  .list-container
  .list-content
  .list-header
  .list-item
  .list-checkbox
  .clinical-list-container
  .clinical-list-content

*/

}


